import styled from 'styled-components'

export const WikiContentHeading = styled.h1`
  text-transform: uppercase;
`

export const WikiContentSubHeading = styled.h2`
  font-weight: 500;
`

export const WikiContentContent = styled.div`
  color: #212121;
`
