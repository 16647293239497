import { commerce } from '../../commerce'

export const fetchShippingMethods = (checkoutId, country, region) => {
  return commerce.checkout
    .getShippingOptions(checkoutId, {
      country,
      region,
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log('There was an error in generating a token', error)
    })
}
