import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'

interface ProductItemBoxProps {
  readonly delay: number
}

const plop = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(-20px);
  }
  100% {
      opacity: 1;
      transform: scale(1) translateY(0px);
  }
`

export const ProductItemBox = styled(Link)<ProductItemBoxProps>`
  margin: 0.5rem 0;
  color: black;
  text-decoration: none;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
  &:hover {
    box-shadow: 0 2.7px 2.2px rgba(0, 0, 0, 0.056), 0 6.4px 5.3px rgba(0, 0, 0, 0.081), 0 12px 10px rgba(0, 0, 0, 0.1),
      0 21.4px 17.9px rgba(0, 0, 0, 0.119), 0 40.1px 33.4px rgba(0, 0, 0, 0.144), 0 96px 80px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: box-shadow, transform 0.3s ease-in-out;
  }
  @media (min-width: 567px) {
    flex-basis: 49%;
  }
  @media (min-width: 992px) {
    flex-basis: 32%;
  }
  display: flex;
  flex-direction: column;
  animation: 0.2s linear 0s 1 normal both running ${plop};
  animation-delay: ${({ delay }) => delay * 0.08}s;
`

export const ProductItemImageWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 56.25%; // 16:9
  position: relative;
`

export const ProductItemImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

export const ProductItemTextBox = styled.div`
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const ProductListHeading = styled.h1`
  text-transform: uppercase;
  white-space: pre-line;
`

export const ProductItemTitle = styled.h2`
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;
`

export const ProductItemDescription = styled.div`
  color: #212121;
  flex-grow: 1;
`

export const ProductItemPriceBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ProductItemPrice = styled.div`
  margin: 1rem 0 0;
  font-size: 1.4rem;
`
