import React from 'react'
import { Remarkable } from 'remarkable'
import { Link } from 'gatsby'
import { ImageArrayType } from '@types'

import {
  FooterBox,
  FooterTextBox,
  FooterImageBox,
  FooterLink,
  FooterLinks,
  FooterAdress,
  FooterImage,
  FooterCopyright,
} from './FooterStyles'

interface Props {
  readonly images: ImageArrayType
  readonly adress: string
  readonly links: Array<{
    title: string
    path: string
  }>
}

export const Footer = ({ images, adress, links }: Props) => (
  <>
    <FooterBox>
      <FooterTextBox>
        <FooterAdress dangerouslySetInnerHTML={{ __html: new Remarkable().render(adress) }} />
        <FooterLinks>
          {links.map((link, index) => {
            return (
              <FooterLink key={String(index)}>
                <Link to={link.path}>{`${link.title}`}</Link>
              </FooterLink>
            )
          })}
        </FooterLinks>
      </FooterTextBox>
      <FooterImageBox>
        {images.map((image) => (
          <FooterImage
            alt={image.directus_files_id.title}
            key={image.directus_files_id.id}
            image={image.directus_files_id.mediaUrl.childImageSharp.gatsbyImageData}
          />
        ))}
      </FooterImageBox>
    </FooterBox>
    <FooterCopyright>&copy; 2021 Hellochili</FooterCopyright>
  </>
)
