import React, { useContext } from 'react'
import { CheckoutStateContext } from '@contexts'

import { CheckoutDiscount } from './CheckoutDiscount'
import { CheckoutShipping } from './CheckoutShipping'

import { CheckoutSummaryHeader, CheckoutSummaryHeading, CheckoutSummaryRow } from './CheckoutSummaryStyles'
import { CheckoutProductItem } from './CheckoutProductItem'

export const CheckoutSummary = () => {
  const { live, loaded } = useContext(CheckoutStateContext)
  return loaded && live ? (
    <>
      <CheckoutSummaryHeader>Bestellübersicht</CheckoutSummaryHeader>
      {live.line_items.map((item) => (
        <CheckoutProductItem item={item} key={item.id} />
      ))}
      <CheckoutSummaryRow isBorder>
        <CheckoutSummaryHeading>Zwischensumme</CheckoutSummaryHeading>
        <CheckoutSummaryHeading>{`${live.subtotal.formatted} €`}</CheckoutSummaryHeading>
      </CheckoutSummaryRow>
      <CheckoutShipping />
      <CheckoutDiscount />
      <CheckoutSummaryRow isBorder>
        <CheckoutSummaryHeading>Fällige Gesamtsumme</CheckoutSummaryHeading>
        <CheckoutSummaryHeading>{`${live.total.formatted} €`}</CheckoutSummaryHeading>
      </CheckoutSummaryRow>
    </>
  ) : null
}
