import { CartType } from '@types'
import { commerce } from '../../../commerce'

import { CartAction } from '../types'

export const addToCart = (
  dispatch: React.Dispatch<CartAction>,
  productId: string,
  amount: number,
  variantId: string,
) => {
  dispatch({ type: 'ADD_TO_CART' })
  commerce.cart.add(productId, amount, variantId).then((res: { cart: CartType }) => {
    dispatch({
      type: 'FINISHED',
      payload: {
        cart: res.cart,
      },
    })
  })
}
