import React from 'react'
import { MediaType } from '@types'

import { NavBarLogoImage, NavBarLogoBox } from './NavBarLogoStyles'

interface Props {
  readonly logo: MediaType
}

export const NavBarLogo = ({ logo }: Props) => (
  <NavBarLogoBox to="/">
    <NavBarLogoImage src={logo.mediaUrl.publicURL} />
  </NavBarLogoBox>
)
