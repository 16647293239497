import styled from 'styled-components'

export const CheckoutOuterBox = styled.div`
  padding-bottom: 3rem;
`

export const CheckoutHeader = styled.div`
  margin-top: 2rem;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CheckoutSummaryButton = styled.div`
  cursor: pointer;
  z-index: 102;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    opacity: 0;
    visibility: hidden;
  }
  span {
    margin-right: 0.5rem;
  }
`

export const CheckoutBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const CheckoutInnerProcessBox = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    flex-basis: 48%;
  }
`
interface CheckoutInnerSummaryBoxProps {
  isMobileOpen: boolean
}

export const CheckoutInnerSummaryBox = styled.div<CheckoutInnerSummaryBoxProps>`
  max-height: ${({ isMobileOpen }) => (isMobileOpen ? '100%' : '0')};
  overflow: hidden;
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  padding: 0 1rem;
  padding-top: ${({ isMobileOpen }) => (isMobileOpen ? '1.2rem' : '0')};
  padding-bottom: ${({ isMobileOpen }) => (isMobileOpen ? '2rem' : '0')};
  transition: all 0.4s;
  z-index: 101;
  box-shadow: 0 1.2px 2.2px rgba(0, 0, 0, 0.02), 0 2.8px 5.3px rgba(0, 0, 0, 0.028), 0 5.3px 10px rgba(0, 0, 0, 0.035),
    0 9.4px 17.9px rgba(0, 0, 0, 0.042), 0 17.5px 33.4px rgba(0, 0, 0, 0.05), 0 42px 80px rgba(0, 0, 0, 0.07);

  @media (min-width: 768px) {
    position: static;
    flex-basis: 48%;
    max-height: none;
    box-shadow: none;
    padding: 0;
  }
`
