import React, { useState, useContext, useEffect } from 'react'
import { useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import {
  captureManualOrder,
  CartDispatchContext,
  CheckoutDispatchContext,
  CheckoutStateContext,
  secureConfirmOrder,
} from '@contexts'
import { fetchPayPalAuth } from '@api'
import { CheckoutMethodType, CustomerDataType } from '@types'

import { Alert } from '@components'
import { ArrowLeft } from '@icons'
import { CheckoutHeading } from '../CheckoutProcessStyles'

import { CheckoutSubmitButton } from './CheckoutSubmitButton'
import { renderPayPalButton } from './renderPayPalButton'
import { getPaymentMethodResponse } from './getPaymentMethodResponse'
import {
  CheckoutBackButton,
  CheckoutPaymentMethodBox,
  CheckoutPaymentPaypalBox,
  CheckoutSpacer,
} from './CheckoutPaymentPageStyles'
import { CheckoutPaymentSwitch } from './CheckoutPaymentSwitch'
import { renderPaymentElement } from './renderPaymentElement'

interface Props {
  readonly customerData: CustomerDataType
  readonly onBackHandler: () => void
}

export const CheckoutPaymentPage = ({ customerData, onBackHandler }: Props) => {
  const [checkoutMethod, setCheckoutMethod] = useState('card' as CheckoutMethodType)
  const [loadingCheckout, setLoadingCheckout] = useState(false)
  const [error, setError] = useState({ message: '', isVisible: false })
  const [sofort, setSofort] = useState({ name: '', country: '' })
  // const [paymentRequest, setPaymentRequest] = useState(null as any)
  const cartDispatch = useContext(CartDispatchContext)
  const { checkout, loaded } = useContext(CheckoutStateContext)
  const checkoutDispatch = useContext(CheckoutDispatchContext)
  const stripe = useStripe()
  const elements = useElements()

  const onPaymentHandler = async () => {
    if (!stripe || !elements) {
      return
    }
    try {
      if (checkoutMethod === 'sofort') {
        captureManualOrder(stripe, sofort, checkout, customerData)
      } else {
        setLoadingCheckout(true)
        setError({ message: '', isVisible: false })
        const paymentMethodResponse = await getPaymentMethodResponse(
          checkoutMethod,
          stripe,
          elements,
          customerData,
          sofort,
        )
        if (paymentMethodResponse.paymentMethod) {
          const error: { isVisible: true; message: string } = await secureConfirmOrder(
            stripe,
            checkoutDispatch,
            cartDispatch,
            checkout,
            customerData,
            {
              gateway: 'stripe',
              stripe: { payment_method_id: paymentMethodResponse.paymentMethod.id },
            },
          )
          if (error.isVisible) {
            setLoadingCheckout(false)
            setError(error)
          }
        } else {
          setLoadingCheckout(false)
          setError({ isVisible: true, message: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut' })
        }
      }
    } catch (e) {
      setLoadingCheckout(false)
      setError({ isVisible: true, message: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut' })
    }
  }

  const onPaypalHandler = (paymentId: string, payerId: string) => {
    if (!stripe) {
      return
    }
    secureConfirmOrder(stripe, checkoutDispatch, cartDispatch, checkout, customerData, {
      gateway: 'paypal',
      paypal: {
        action: 'capture',
        payment_id: paymentId,
        payer_id: payerId,
      },
    })
  }

  useEffect(() => {
    const fetchPaypal = async () => {
      if (customerData.firstName && customerData.lastName) {
        setSofort({ name: `${customerData.firstName} ${customerData.lastName}`, country: 'DE' })
      }
      const element = document.getElementById('paypal-button-container')
      if (element && !element.firstChild) {
        if (customerData.firstName && loaded) {
          renderPayPalButton(await fetchPayPalAuth(checkout, customerData), onPaypalHandler, setError)
        }
      }
    }
    fetchPaypal()
  }, [customerData])

  // useEffect(() => {
  //   if (stripe && checkout) {
  //     const pr = stripe.paymentRequest({
  //       country: 'DE',
  //       currency: 'eur',
  //       total: {
  //         label: 'Hellochili Shop',
  //         amount: 1099,
  //       },
  //       requestPayerName: true,
  //       requestPayerEmail: true,
  //     })
  //     // Check the availability of the Payment Request API.
  //     pr.canMakePayment().then((result) => {
  //       if (result) {
  //         pr.on('paymentmethod', async (ev) => {
  //           if (!stripe || !elements) {
  //             return
  //           }
  //           try {
  //             setLoadingCheckout(true)
  //             setError({ message: '', isVisible: false })
  //             if (ev.paymentMethod) {
  //               const error: { isVisible: true; message: string } = await secureConfirmOrder(
  //                 stripe,
  //                 checkoutDispatch,
  //                 cartDispatch,
  //                 checkout,
  //                 customerData,
  //                 {
  //                   gateway: 'stripe',
  //                   stripe: { payment_method_id: ev.paymentMethod.id },
  //                 },
  //               )
  //               if (error.isVisible) {
  //                 setLoadingCheckout(false)
  //                 setError(error)
  //               }
  //             } else {
  //               setLoadingCheckout(false)
  //               setError({ isVisible: true, message: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut' })
  //             }
  //           } catch (e) {
  //             setLoadingCheckout(false)
  //             setError({ isVisible: true, message: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut' })
  //           }
  //         })
  //         setPaymentRequest(pr)
  //       }
  //     })
  //   }
  // }, [stripe])

  return (
    <>
      <CheckoutHeading>Zahlungsinformationen</CheckoutHeading>
      {/* <CheckoutPaymentApplePayBox>
        {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
      </CheckoutPaymentApplePayBox> */}
      <CheckoutPaymentPaypalBox>
        <div id="paypal-button-container" />
      </CheckoutPaymentPaypalBox>
      <CheckoutSpacer>
        <hr />
        <p>oder</p>
      </CheckoutSpacer>
      <CheckoutPaymentSwitch checkoutMethod={checkoutMethod} setCheckoutMethod={setCheckoutMethod} />
      <CheckoutPaymentMethodBox checkoutMethod={checkoutMethod}>
        {renderPaymentElement(checkoutMethod, customerData, sofort, setSofort)}
      </CheckoutPaymentMethodBox>
      <CheckoutSubmitButton
        onClickHandler={!loadingCheckout ? onPaymentHandler : undefined}
        isDisabled={!stripe || !elements}
        isLoading={loadingCheckout}
      />
      <Alert isSuccess={false} isVisible={error.isVisible} message={error.message} />
      <CheckoutBackButton type="button" onClick={onBackHandler}>
        <ArrowLeft />
        <span>Versandadresse bearbeiten</span>
      </CheckoutBackButton>
    </>
  )
}
