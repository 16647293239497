import { Stripe } from '@stripe/stripe-js'
import { CheckoutType, CustomerDataType, OrderType } from '@types'
import { convertCustomerData } from '@helper'

import { commerce } from '../../../commerce'

import { CheckoutAction } from '../types'

export const captureManualOrder = async (
  stripe: Stripe,
  sofort: { name: string; country: string },
  // checkoutDispatch: React.Dispatch<CheckoutAction>,
  checkoutToken: CheckoutType,
  customerData: CustomerDataType,
) => {
  // checkoutDispatch({ type: 'UPDATING' })
  return commerce.checkout
    .capture(checkoutToken.id, {
      _line_items: checkoutToken.products,
      ...convertCustomerData(customerData),
      payment: {
        gateway: 'manual',
        manual: {
          id: 'gway_JwrgDAaDP14XoP',
        },
      },
    })
    .then((order: OrderType) => {
      const getPayment = async () => {
        const { source } = await stripe.createSource({
          type: 'sofort',
          amount: 150,
          currency: order.currency.code,
          redirect: {
            return_url: 'https://shop.example.com/crtA6B28E1',
          },
          sofort: {
            country: sofort.country,
          },
        })
        // const paymentMethodResponse = await stripe.createPaymentMethod({
        //   type: 'sofort',
        //   sofort: {
        //     country: sofort.country,
        //   },
        //   billing_details: {
        //     name: sofort.name,
        //   },
        // })
        if (source) {
          const sofortpayment = await stripe.confirmSofortPayment(source.client_secret, {
            payment_method: {
              sofort: {
                country: sofort.country,
              },
              billing_details: {
                name: sofort.name,
              },
            },
          })
        }
      }
      getPayment()
    })
}
