/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext } from 'react'
import { fetchShippingMethods } from '@api'
import { CheckoutDispatchContext, CheckoutStateContext, checkShipping } from '@contexts'

import { UseFormRegister } from 'react-hook-form'
import { CustomerDataType, ShippingMethodType } from '@types'
import { CheckoutSelect } from '../../CheckoutInputStyles'

interface Props {
  readonly register: UseFormRegister<CustomerDataType>
  readonly country: string
  readonly region: string
}

export const CheckoutShippingSelector = ({ country, region, register }: Props) => {
  const [shipping, setShipping] = useState('ship_RqEv5xgkdoZz4j')
  const [shippingMethods, setShippingMethods] = useState([] as Array<ShippingMethodType>)
  const { checkout } = useContext(CheckoutStateContext)
  const dispatch = useContext(CheckoutDispatchContext)

  useEffect(() => {
    const getShippingMethods = async () => {
      if (checkout && country && region) {
        const avaliableShippingMethods = await fetchShippingMethods(checkout.id, country, region)
        if (avaliableShippingMethods) {
          setShipping(avaliableShippingMethods[0].id)
          setShippingMethods(avaliableShippingMethods)
          if (avaliableShippingMethods.length === 1) {
            checkShipping(dispatch, checkout.id, avaliableShippingMethods[0].id, country, region)
          }
        }
      }
    }
    getShippingMethods()
  }, [checkout, country, region])

  const onChangeHandler = (e: { target: { value: string } }) => {
    const newShipping = e.target.value
    checkShipping(dispatch, checkout.id, newShipping, country, region)
    setShipping(newShipping)
  }

  return (
    <>
      <input value={shipping} type="hidden" {...register('shipping_method', { required: true })} />
      <CheckoutSelect onChange={onChangeHandler}>
        {shippingMethods &&
          shippingMethods.map((shippingMethod) => (
            <option key={shippingMethod.id} value={shippingMethod.id}>
              {`${shippingMethod.description} (${shippingMethod.price.formatted} € Grundgebühr)`}
            </option>
          ))}
      </CheckoutSelect>
    </>
  )
}
