import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CustomerDataType } from '@types'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import { CheckoutHeading, CheckoutSubmitButton } from '../CheckoutProcessStyles'

import { CheckoutCustomerInfo } from './CheckoutCustomerInfo'
import { CheckoutShippingInfo } from './CheckoutShippingInfo'
import { CheckoutBillingInfo } from './CheckoutBillingInfo'
import { CheckoutCheckbox } from './CheckoutInputStyles'

interface Props {
  readonly onSubmitHandler: (newData: CustomerDataType) => void
}

export const CheckoutCustomerPage = ({ onSubmitHandler }: Props) => {
  const [isChecked, setIsChecked] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<CustomerDataType>()

  return (
    <form onSubmit={handleSubmit((data: CustomerDataType) => onSubmitHandler(data))}>
      <CheckoutHeading>Kundeninformationen</CheckoutHeading>
      <CheckoutCustomerInfo register={register} errors={errors} />
      <CheckoutHeading>Versandadresse</CheckoutHeading>
      <CheckoutShippingInfo
        register={register}
        errors={errors}
        setError={setError}
        setValue={setValue}
        clearErrors={clearErrors}
      />
      <CheckoutCheckbox type="checkbox" value={String(isChecked)} onChange={(e) => setIsChecked(e.target.checked)} />
      <span>Abweichende Rechnungsadresse</span>
      {isChecked && (
        <CheckoutBillingInfo
          register={register}
          errors={errors}
          setError={setError}
          setValue={setValue}
          clearErrors={clearErrors}
        />
      )}
      <CheckoutSubmitButton type="submit">Weiter</CheckoutSubmitButton>
    </form>
  )
}
