import { useState, useEffect } from 'react'

export const useWindowScroll = () => {
  const [windowScroll, setWindowScroll] = useState(0)

  useEffect(() => {
    const handleScroll = () => setWindowScroll(window.pageYOffset)

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return [windowScroll]
}
