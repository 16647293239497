export const renderPayPalButton = (
  paypalAuth: { payment_id: string },
  onPaypalHandler: (paymentId: string, payerId: string) => void,
  setError: (error: { message: string; isVisible: boolean }) => void,
) => {
  // eslint-disable-next-line no-undef
  paypal.Button.render(
    {
      style: {
        size: 'large',
        tagline: false,
      },
      env: 'production', // 'production' Or 'sandbox',
      commit: true, // Show a 'Pay Now' button
      payment() {
        return paypalAuth.payment_id // The payment ID from earlier
      },
      onAuthorize(data) {
        onPaypalHandler(data.paymentID, data.payerID)
      },
      onCancel() {
        setError({ isVisible: true, message: 'Ein Fehler ist aufgetreten. Paypal wurde nicht richtig autorisiert' })
        // Handler if customer does not authorize payment
      },
    },
    '#paypal-button-container',
  )
}
