import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Footer as DefaultFooter } from '../components'

const menu = [
  {
    title: 'Datenschutz',
    path: '/datenschutz',
  },
  {
    title: 'Impressum',
    path: '/impressum',
  },
  {
    title: 'Kontakt',
    path: '/#kontakt',
  },
]

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      footer: directus {
        ...FooterFragment
      }
    }
  `)

  return <DefaultFooter images={data.footer.footer.awards} adress={data.footer.footer.adress} links={menu} />
}
