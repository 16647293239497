import styled, { css } from 'styled-components'

export const HamburgerBox = styled.div`
  cursor: pointer;
  position: relative;
  transition: transform 0.3s;
  margin-right: 5vw;
  width: 35px;
  height: 35px;
  @media (min-width: 768px) {
    display: none;
  }
`

interface HamburgerBarProps {
  readonly isOpen: boolean
  readonly number: 1 | 2 | 3
}

export const HamburgerBar = styled.div<HamburgerBarProps>`
  height: 10%;
  transition: opacity 0.35s, transform 0.4s;
  background-color: white;
  border-radius: 4px;

  // eslint-disable-next-line consistent-return
  ${({ number, isOpen }) => {
    switch (number) {
      default:
      case 1:
        return css`
          margin-bottom: 30%;
          transform: ${isOpen && 'rotate(-45deg) translate(-9.66px, 9.66px)'};
        `
      case 2:
        return css`
          opacity: ${isOpen && '0'};
        `
      case 3:
        return css`
          margin-top: 30%;
          transform: ${isOpen && 'rotate(45deg) translate(-9.66px, -9.66px)'};
        `
    }
  }}
`
