import React from 'react'

import { LoadingCircleSvg, LoadingCircleCircle } from './LoadingCircleStyles'

interface Props {
  readonly color: string
  readonly width: string
  readonly height: string
}

export const LoadingCircle = ({ width, height, color }: Props) => (
  <LoadingCircleSvg viewBox="25 25 50 50" style={{ width, height }}>
    <LoadingCircleCircle cx="50" cy="50" r="20" fill="none" stroke={color} strokeWidth="2" color={color} />
  </LoadingCircleSvg>
)
