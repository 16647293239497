import React, { useState, useEffect } from 'react'
import { MediaType } from '@types'

import { NavBarLogo } from './NavBarLogo'
import { NavBarMenu } from './NavBarMenu'
import { NavBarSideMenu } from './NavBarSideMenu'
import { NavBarOffScreen } from './NavBarOffScreen'

import { NavBarBox, NavBarWrapper } from './NavBarStyles'

interface Props {
  readonly logo: MediaType
  readonly menu: Array<{ title: string; path: string }>
}

export const NavBar = ({ logo, menu }: Props) => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false)
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false)
  const [anchorTarget, setAnchorTarget] = useState(null as HTMLElement | null)

  useEffect(() => {
    setAnchorTarget(document.getElementById('navigation'))
  }, [])

  const handleScrollIntoView = () => {
    if (!isNavBarOpen && !isShoppingCartOpen) anchorTarget!.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <NavBarWrapper>
      <NavBarBox id="navigation">
        <NavBarLogo logo={logo} />
        <NavBarMenu
          isHamburgerOpen={isNavBarOpen}
          menu={menu}
          onHamburgerClickHandler={() => {
            setIsNavBarOpen(!isNavBarOpen)
            setIsShoppingCartOpen(false)
            handleScrollIntoView()
          }}
          onShoppingCartClickHandler={() => {
            setIsShoppingCartOpen(!isShoppingCartOpen)
            setIsNavBarOpen(false)
            handleScrollIntoView()
          }}
        />
        <NavBarOffScreen
          onClickHandler={() => {
            setIsNavBarOpen(false)
            setIsShoppingCartOpen(false)
          }}
          isVisible={isNavBarOpen || isShoppingCartOpen}
        />
      </NavBarBox>
      <NavBarSideMenu
        menu={menu}
        isNavBarOpen={isNavBarOpen}
        isShoppingCartOpen={isShoppingCartOpen}
        onLinkClickHandler={() => setIsNavBarOpen(false)}
      />
    </NavBarWrapper>
  )
}
