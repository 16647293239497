import styled from 'styled-components'

export const ProductDetailHeading = styled.h1`
  margin-top: 0;
  text-transform: uppercase;
`

export const ProductDetailSubHeading = styled.h2`
  text-transform: uppercase;
`

export const ProductDetailBox = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ProductDetailProduct = styled.div`
  @media (min-width: 768px) {
    margin: 0 1rem;
    flex-basis: 50%;
  }
`

export const ProductDetailLabel = styled.h3`
  text-transform: uppercase;
  padding: 1rem 0 0;
`

export const ProductDetailShortDescription = styled.p`
  color: #5d5d5d;
  margin: 0;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`

export const ProductDetailPrice = styled.div`
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

export const ProductDetailButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductDetailDescription = styled.div`
  /* font-size: 1rem; */
  color: #212121;
`
