import React from 'react'
import Flickity from 'react-flickity-component'
import { ProductType } from '@types'

import { SliderBox, SliderSlide, SliderOverlay, SliderImage, SliderText, SliderCTA } from './SliderStyles'

interface Props {
  readonly products: Array<ProductType>
  readonly isProductsLoaded: boolean
}

export const Slider = ({ products, isProductsLoaded }: Props) => {
  return (
    <SliderBox>
      <Flickity
        className="carousel" // default ''
        elementType="div" // default 'div'
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        options={{ wrapAround: true, autoPlay: 5000 }}
        static // default false
      >
        {isProductsLoaded
          ? products.map((product) => (
              <SliderSlide key={product.id}>
                <SliderImage style={{ backgroundImage: `url(${product.media.source})` }} />
                <SliderOverlay />
                <SliderText>{product.seo.description}</SliderText>
                <SliderCTA to={`/product/${product.permalink}`}>Jetzt Kaufen</SliderCTA>
              </SliderSlide>
            ))
          : null}
      </Flickity>
    </SliderBox>
  )
}
