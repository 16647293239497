import { CartType, ProductType, VariantType } from '@types'
import { getCartQuantity } from './getCartQuantity'

export const getCurrentMaxAmount = (
  cart: CartType,
  product: ProductType,
  existVariants: boolean,
  selectedVariant: VariantType | null,
) => {
  if (existVariants && selectedVariant && selectedVariant.inventory) {
    return selectedVariant.inventory - getCartQuantity(cart, product)
  }
  if (!existVariants && product.inventory) {
    return product.inventory.available - getCartQuantity(cart, product)
  }
  return 0
}
