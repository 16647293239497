import { CheckoutMethodType } from '@types'
import styled from 'styled-components'

export const CheckoutBackButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-top: 0.8rem;
  span {
    font-size: 0.8rem;
    margin-left: 0.3rem;
  }
`

export const CheckoutSpacer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: rgba(26, 26, 26, 0.1);
  }

  p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 8px;
    white-space: nowrap;
    background-color: #fff;
    color: gray;
  }
`

interface CheckoutPaymentMethodBoxProps {
  readonly checkoutMethod: CheckoutMethodType
}

export const CheckoutPaymentMethodBox = styled.div<CheckoutPaymentMethodBoxProps>`
  margin-bottom: 1rem;
  max-height: ${({ checkoutMethod }) => (checkoutMethod === 'card' ? '20px' : 'none')};
`

export const CheckoutPaymentPaypalBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckoutPaymentApplePayBox = styled.div`
  margin-bottom: 1rem;
`
