import { navigate } from 'gatsby'
import { CartType, CheckoutType } from '@types'

import { commerce } from '../../../commerce'

import { CheckoutAction } from '../types'

export const generateCheckout = (dispatch: React.Dispatch<CheckoutAction>, cart: CartType) => {
  dispatch({ type: 'LOADING' })
  commerce.checkout
    .generateToken(cart.id, { type: 'cart' })
    .then((checkout: CheckoutType) => {
      dispatch({
        type: 'UPDATED_CHECKOUT',
        payload: {
          checkout,
        },
      })
    })
    .catch((error: { data: { status_code: number } }) => {
      // Cart is empty, Navigate to Products
      if (error.data.status_code === 422) {
        navigate('/products')
      }
    })
}
