import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

export const GlobalStyles = createGlobalStyle`
    ${normalize}

    * {
      box-sizing: border-box;
    }

    html {
      scroll-behavior: smooth;
    }

    body {
      font-family: 'Lato', sans-serif;
      background-color: black;
    }

    h1 {
      font-family: 'Barlow Semi Condensed', sans-serif;
    }

    p {
      font-weight: 300;
    }

    a {
      color: #880606;
    }

    #___gatsby {
      background-color: white;
    }

    .grecaptcha-badge {
        display: none !important;
    }
`
