import React, { useContext } from 'react'
import { CheckoutStateContext } from '@contexts'

import { CheckoutSummaryRow, CheckoutSummarySubHeading } from '../CheckoutSummaryStyles'

export const CheckoutShipping = () => {
  const { live } = useContext(CheckoutStateContext)
  return live.shipping.id ? (
    <CheckoutSummaryRow>
      <CheckoutSummarySubHeading>{live.shipping.description}</CheckoutSummarySubHeading>
      <CheckoutSummarySubHeading>{`${live.shipping.price.formatted} €`}</CheckoutSummarySubHeading>
    </CheckoutSummaryRow>
  ) : null
}
