import React from 'react'
import { CardElement, IbanElement } from '@stripe/react-stripe-js'
import { CheckoutMethodType, CustomerDataType } from '@types'
import { CheckoutSofortCountryList } from './CheckoutSofortCountryList'
import { CheckoutInput } from '../CheckoutCustomerPage/CheckoutInputStyles'

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '1.2rem',
      fontSmoothing: 'antialiased',
    },
  },
}

export const renderPaymentElement = (
  checkoutMethod: CheckoutMethodType,
  customerData: CustomerDataType,
  sofort: { name: string; country: string },
  setSofort: (newSofort: { name: string; country: string }) => void,
) => {
  switch (checkoutMethod) {
    case 'card':
      return <CardElement options={CARD_OPTIONS} />
    case 'sofort':
      return (
        <>
          <CheckoutInput
            type="text"
            id="sofortName"
            name="Name"
            defaultValue={`${customerData.firstName} ${customerData.lastName}`}
            isError={false}
            onChange={(e) => setSofort({ ...sofort, name: e.target.value })}
          />
          <CheckoutSofortCountryList
            defaultValue={customerData.billingCountry || customerData.country}
            sofort={sofort}
            setSofort={setSofort}
          />
        </>
      )
    // case 'iban':
    //   return <IbanElement options={{ supportedCountries: ['SEPA'] }} />
    default:
      return null
  }
}
