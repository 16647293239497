import styled from 'styled-components'
import { Link } from 'gatsby'

interface ShoppingCartCardBoxProps {
  readonly isOpen: boolean
}

export const ShoppingCartBox = styled.div<ShoppingCartCardBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 0 2rem;
  height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 90px)' : '0')};
  width: ${({ isOpen }) => (isOpen ? '100vw' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0px)' : 'translateX(400px)')};
  padding-bottom: 2rem;
  @media (min-width: 567px) {
    width: ${({ isOpen }) => (isOpen ? '420px' : '0')};
  }
`

export const ShoppingCartItemBox = styled.div`
  overflow-y: scroll;
  width: 100%;
`

export const ShoppingCartSubtotal = styled.div`
  margin: 1rem 0;
  font-size: 1.5rem;
`

interface ShoppingCartOrderButtonProps {
  readonly $isLinkEnabled: boolean
}

export const ShoppingCartOrderButton = styled(Link)<ShoppingCartOrderButtonProps>`
  position: relative;
  transition: 0.3s ease-in-out;
  height: 60px;
  overflow: hidden;
  padding: 1.5rem 0.5rem;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: ${({ $isLinkEnabled }) => ($isLinkEnabled ? 'pointer' : 'auto')};
  pointer-events: ${({ $isLinkEnabled }) => ($isLinkEnabled ? 'auto' : 'none')};
  opacity: ${({ $isLinkEnabled }) => ($isLinkEnabled ? '1' : '0.5')};
  text-decoration: none;
  color: black;
`
