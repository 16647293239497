import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const StyledLink = styled(AnchorLink)`
  position: relative;
  transition: all 0.3s;
  text-decoration: none;
  font-size: 1rem;
  color: white;
  font-weight: 800;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  padding: 1rem;
  @media (min-width: 768px) {
    padding: 0.5rem;
  }
  transition: width 0.5s;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: white;
    height: 1px;
    transition: width 0.5s;
  }

  &:hover:after {
    width: 100%;
  }
`
