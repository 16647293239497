import { navigate } from 'gatsby'
import { CartType, OrderType } from '@types'
import { commerce } from '../../../commerce'

import { CartAction } from '../types'

export const refreshCart = (dispatch: React.Dispatch<CartAction>, order: OrderType) => {
  dispatch({ type: 'UPDATING' })
  commerce.cart.refresh().then((cart: CartType) => {
    dispatch({
      type: 'FINISHED',
      payload: {
        cart,
      },
    })
    navigate('/confirmation', { state: { order } })
  })
}
