import { CheckoutLiveType } from '@types'
import { commerce } from '../../../commerce'

import { CheckoutAction } from '../types'

export const checkQuantity = (
  dispatch: React.Dispatch<CheckoutAction>,
  checkoutId: string,
  lineItemId: string,
  quantity: number,
  variantId: string,
) => {
  dispatch({ type: 'UPDATING' })
  commerce.checkout
    .checkQuantity(checkoutId, lineItemId, {
      amount: quantity,
      variant_id: variantId,
    })
    .then((res: { live: CheckoutLiveType }) => {
      dispatch({
        type: 'UPDATED_LIVE',
        payload: {
          live: res.live,
        },
      })
    })
}
