import styled, { css } from 'styled-components'

interface TrashContainerProps {
  readonly isOpen: boolean
}

export const TrashContainer = styled.span<TrashContainerProps>`
  cursor: pointer;
  background: white;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 1rem 0.4rem 0.4rem;
  position: relative;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  ${({ isOpen }) =>
    isOpen &&
    css`
      span {
        transform: rotate(-45deg);
        transition: transform 250ms;
      }
    `}
`

export const TrashHead = styled.span`
  position: absolute;
  height: 3px;
  background: white;
  top: -5px;
  left: -3px;
  right: -3px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: white;
    top: -5px;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform: rotate(0deg);
    transition: transform 250ms;
    transform-origin: 19% 100%;
    left: 7px;
  }
`

const TrashLine = css`
  background: black;
  width: 2px;
  height: 13px;
  border-radius: 5px;
`

export const TrashBody = styled.i`
  position: relative;
  display: block;
  margin: 3px auto 0;
  ${TrashLine}
  &:after {
    content: '';
    position: absolute;
    left: -5px;
    ${TrashLine}
  }
  &:before {
    content: '';
    position: absolute;
    right: -5px;
    ${TrashLine}
  }
`
