import styled from 'styled-components'

interface AlertContainerProps {
  readonly isVisible: boolean
  readonly isSuccess: boolean
  readonly isClickable: boolean
}

export const AlertContainer = styled.div<AlertContainerProps>`
  background-color: ${({ isSuccess }) => (isSuccess ? '#b8e994' : '#e55039')};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  text-decoration: ${({ isClickable }) => (isClickable ? 'underline' : 'none')};
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  transition: opacity 0.3s;
`
