import React from 'react'
import { Card, Sofort } from '@icons'
import { CheckoutMethodType } from '@types'

import {
  CheckoutPaymentSwitchButton,
  CheckoutPaymentSwitchContainer,
  CheckoutPaymentSwitchText,
} from './CheckoutPaymentSwitchStyles'

interface Props {
  checkoutMethod: CheckoutMethodType
  setCheckoutMethod: (checkoutMethod: CheckoutMethodType) => void
}

export const CheckoutPaymentSwitch = ({ checkoutMethod, setCheckoutMethod }: Props) => (
  <CheckoutPaymentSwitchContainer>
    <CheckoutPaymentSwitchButton
      type="button"
      onClick={() => setCheckoutMethod('card')}
      disabled={checkoutMethod === 'card'}
      isSelected={checkoutMethod === 'card'}
    >
      <Card />
    </CheckoutPaymentSwitchButton>
    <CheckoutPaymentSwitchButton
      type="button"
      isDisabled
      // onClick={() => setCheckoutMethod('sofort')}
      disabled={checkoutMethod === 'sofort'}
      isSelected={checkoutMethod === 'sofort'}
    >
      <CheckoutPaymentSwitchText> nicht verfügbar</CheckoutPaymentSwitchText>
      <Sofort />
    </CheckoutPaymentSwitchButton>
  </CheckoutPaymentSwitchContainer>
)
