import { ShoppingCart } from '@components'
import React from 'react'
import { NavBarLink } from '../NavBarLink'
import { NavBarMobileMenuWrapper, NavBarMobileMenu, InvisibleButton } from './NavBarSideMenuStyles'

interface Props {
  readonly isNavBarOpen: boolean
  readonly isShoppingCartOpen: boolean
  readonly menu: Array<{ title: string; path: string }>
  readonly onLinkClickHandler: () => void
}

export const NavBarSideMenu = ({ menu, isNavBarOpen, onLinkClickHandler, isShoppingCartOpen }: Props) => (
  <NavBarMobileMenuWrapper isOpen={isNavBarOpen || isShoppingCartOpen}>
    <NavBarMobileMenu isOpen={isNavBarOpen}>
      {menu.map(({ title, path }, index) => (
        <InvisibleButton key={String(index)} onClick={onLinkClickHandler}>
          <NavBarLink title={title} path={path} />
        </InvisibleButton>
      ))}
    </NavBarMobileMenu>
    <ShoppingCart isOpen={isShoppingCartOpen} />
  </NavBarMobileMenuWrapper>
)
