import React, { useEffect, useState, useMemo } from 'react'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import mapboxgl from 'mapbox-gl'
import ReactMapGL, { Marker } from 'react-map-gl'
import { accessToken } from '@model'
import { Marker as MarkerIcon } from '@icons'
import { AdressType, GeocodeResultType, ViewPortType } from '@types'

import { ConfiormationAdressBox, ConfirmationAdressGeoCode } from './ConfirmationShippingStyles'

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires */
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved, @typescript-eslint/no-var-requires */

interface Props {
  readonly adress: AdressType
  readonly elementId: string
}

export const ConfirmationShipping = ({ adress, elementId }: Props) => {
  const [viewport, setViewport] = useState({} as ViewPortType)
  const [shippingMarker, setShippingMarker] = useState({ latitude: 0, longitude: 0, zoom: 0 } as ViewPortType)

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      limit: 1,
      accessToken,
    })
    geocoder.on('result', ({ result }: GeocodeResultType) => {
      const adressLocation = {
        longitude: result.center[0],
        latitude: result.center[1],
        zoom: 17,
      }
      setShippingMarker(adressLocation)
      setViewport(adressLocation)
      const nodes = document.getElementsByClassName(elementId)
      while (nodes[0]) {
        nodes[0].remove()
      }
    })
    geocoder.addTo(`#${elementId}`)
    geocoder.query(`${adress.street}, ${adress.postal_zip_code}, ${adress.town_city} ${adress.country}`)
  }, [])

  const markers = useMemo(
    () => (
      <Marker longitude={shippingMarker.longitude} latitude={shippingMarker.latitude} offsetLeft={-10} offsetTop={-30}>
        <MarkerIcon />
      </Marker>
    ),
    [shippingMarker],
  )

  return (
    <ConfiormationAdressBox>
      <ReactMapGL
        mapboxApiAccessToken={accessToken}
        longitude={viewport.longitude}
        latitude={viewport.latitude}
        zoom={viewport.zoom}
        width="100%"
        height={360}
        onViewportChange={(newViewport: ViewPortType) => setViewport(newViewport)}
      >
        {markers}
      </ReactMapGL>
      <ConfirmationAdressGeoCode className={elementId} id={elementId} />
    </ConfiormationAdressBox>
  )
}
