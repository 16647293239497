import styled from 'styled-components'

export const HeroImageImage = styled.div`
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  @media screen and (min-width: 980px) {
    background-size: 100% auto;
  }
`
