import styled from 'styled-components'

interface CheckoutSubmitButtonBoxProps {
  readonly isLoading: boolean
}

export const CheckoutSubmitButtonBox = styled.button<CheckoutSubmitButtonBoxProps>`
  border-radius: 5px;
  cursor: ${({ isLoading }) => (isLoading ? 'auto' : 'pointer')};
  padding: ${({ isLoading }) => (isLoading ? '0rem' : '1.5rem 0.5rem')};
  width: 100%;
  background-color: black;
  color: white;
  font-size: 1.4rem;
  border: none;
  text-transform: uppercase;
  min-height: 75px;
  margin-bottom: 0.5rem;
`
