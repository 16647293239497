import { GecodeResultContextType, AdressSearchType } from '@types'

export const filterSearchResult = (
  adress: string | undefined,
  text: string,
  context: GecodeResultContextType,
): AdressSearchType => {
  const newAdressObject: AdressSearchType = {
    street: '',
    postal_zip_code: '',
    town_city: '',
    region: '',
    country: '',
  }

  if (adress) {
    newAdressObject.street = `${text} ${adress}`
  } else {
    newAdressObject.street = `${text}`
  }
  // eslint-disable-next-line array-callback-return
  context.map((adressPart) => {
    if (adressPart.id.match('country')) {
      newAdressObject.country = adressPart.short_code.toUpperCase()
    }
    if (adressPart.id.match('region')) {
      if (adressPart.short_code.match('-')) {
        const shortCode = adressPart.short_code.split('-')[1]
        newAdressObject.region = shortCode
      } else {
        newAdressObject.region = adressPart.short_code
      }
    }
    if (adressPart.id.match('place')) {
      newAdressObject.town_city = adressPart.text
    }
    if (adressPart.id.match('postcode')) {
      newAdressObject.postal_zip_code = adressPart.text
    }
  })

  return newAdressObject
}
