import React, { useState, useContext } from 'react'

import { CheckoutDispatchContext, CheckoutStateContext, checkDiscount } from '@contexts'
import { CheckoutSummaryRow, CheckoutSummarySubHeading } from '../CheckoutSummaryStyles'
import { CheckoutDiscountButton, CheckoutDiscountRow } from './CheckoutDiscountStyles'

export const CheckoutDiscount = () => {
  const { checkout, live, isDiscountValid } = useContext(CheckoutStateContext)
  const dispatch = useContext(CheckoutDispatchContext)
  const [discountCode, setDiscountCode] = useState('')

  return (
    <>
      <CheckoutDiscountRow>
        <input
          type="text"
          value={discountCode}
          onChange={(event) => setDiscountCode(event.target.value)}
          placeholder="Rabattcode"
        />
        {isDiscountValid === false && <>Dein Code ist nicht valide</>}
        <CheckoutDiscountButton
          type="submit"
          onClick={() => discountCode && checkDiscount(dispatch, checkout.id, discountCode)}
          disabled={!discountCode}
        >
          Anwenden
        </CheckoutDiscountButton>
      </CheckoutDiscountRow>
      {!Array.isArray(live.discount) && (
        <CheckoutSummaryRow>
          <CheckoutSummarySubHeading>{`Rabattcode ${live.discount.code}`}</CheckoutSummarySubHeading>
          <CheckoutSummarySubHeading>{`-${live.discount.amount_saved.formatted} €`}</CheckoutSummarySubHeading>
        </CheckoutSummaryRow>
      )}
    </>
  )
}
