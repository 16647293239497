import React, { useState } from 'react'
import { WikiType } from '@types'
import { ArrowDown, ArrowUp } from '@icons'

import {
  WikiSideBarLink,
  WikiSideBarLinkBox,
  WikiSideBarSubLink,
  WikiSideBarSubLinkBox,
  InvisibleButton,
} from './WikiSideBarStyles'

interface Props {
  readonly category: string
  readonly wikiItems: Array<WikiType>
}

export const WikiSideBarItem = ({ category, wikiItems }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <WikiSideBarLinkBox>
        <WikiSideBarLink href={`#${category}`}>{category}</WikiSideBarLink>
        <InvisibleButton onClick={() => setIsOpen(!isOpen)}>{isOpen ? <ArrowUp /> : <ArrowDown />}</InvisibleButton>
      </WikiSideBarLinkBox>
      <WikiSideBarSubLinkBox isOpen={isOpen}>
        {wikiItems.map(({ sub_category }: WikiType) => (
          <>
            {sub_category && (
              <WikiSideBarSubLink href={sub_category ? `#${category}_${sub_category}` : ''}>
                {sub_category}
              </WikiSideBarSubLink>
            )}
          </>
        ))}
      </WikiSideBarSubLinkBox>
    </>
  )
}
