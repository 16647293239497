/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import {
  DeepMap,
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'

import { CustomerDataType } from '@types'

import { useCountryAndRegion } from '@hooks'
import { CheckoutHeading } from '../../CheckoutProcessStyles'

import { CheckoutCountryList } from '../CheckoutCountryList'
import { CheckoutSubdivisionList } from '../CheckoutSubdivisionList'
import { CheckoutError, CheckoutInput, CheckoutInputRow, CheckoutSearch } from '../CheckoutInputStyles'
import { CheckoutSpacer } from '../../CheckoutPaymentPage/CheckoutPaymentPageStyles'

interface Props {
  readonly register: UseFormRegister<CustomerDataType>
  readonly setError: UseFormSetError<CustomerDataType>
  readonly setValue: UseFormSetValue<CustomerDataType>
  readonly clearErrors: UseFormClearErrors<CustomerDataType>
  readonly errors: DeepMap<CustomerDataType, FieldError>
}

export const CheckoutBillingInfo = ({ register, setError, setValue, errors, clearErrors }: Props) => {
  const [countries, subdivisions, country, setCountry, region, setRegion] = useCountryAndRegion(
    'billingStreet',
    'billingPostal_zip_code',
    'billingTown_city',
    'billingCountry',
    'billingRegion',
    'geobilling',
    clearErrors,
    setError,
    setValue,
  )

  return (
    <>
      <CheckoutHeading>Rechnungsadresse</CheckoutHeading>
      <CheckoutInput
        isError={!!errors.billingName}
        placeholder="Name"
        {...register('billingName', { required: true })}
      />
      <CheckoutSearch id="geobilling" />
      <CheckoutError isVisible={errors.billingCountry?.type === 'validate'}>
        Dieses Land kann leider nicht beliefert werden
      </CheckoutError>
      <CheckoutError isVisible={errors.billingRegion?.type === 'validate'}>
        Diese Region kann leider nicht beliefert werden
      </CheckoutError>
      <CheckoutSpacer>
        <hr />
        <p>oder manuell eingeben</p>
      </CheckoutSpacer>
      <CheckoutInput
        isError={!!errors.billingStreet}
        placeholder="Adresse"
        {...register('billingStreet', { required: true })}
      />
      <CheckoutInputRow>
        <CheckoutInput
          type="number"
          isError={!!errors.billingPostal_zip_code}
          placeholder="Postleitzahl"
          {...register('billingPostal_zip_code', { required: true })}
        />
        <CheckoutInput
          isError={!!errors.billingTown_city}
          placeholder="Stadt"
          {...register('billingTown_city', { required: true, pattern: /^[A-Za-z]+$/i })}
        />
      </CheckoutInputRow>
      <CheckoutError isVisible={errors.postal_zip_code?.type === 'pattern'}>
        Die Postleitzahl ist unvollständig.
      </CheckoutError>
      <CheckoutSubdivisionList
        subdivisions={subdivisions}
        country={country}
        region={region}
        setRegion={setRegion}
        register={register}
        inputName="billingRegion"
      />
      <CheckoutCountryList
        countries={countries}
        country={country}
        setCountry={setCountry}
        register={register}
        inputName="billingCountry"
      />
    </>
  )
}
