import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Helmet } from 'react-helmet'
import '@fontsource/barlow-semi-condensed'
import '@fontsource/barlow-semi-condensed/600.css'

import { GlobalStyles, LoadingScreen, CookieLaw } from '../components'

import { NavBar } from './_navbar'
import { Footer } from './_footer'
import { CartProvider } from '../model/contexts'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', { offset: () => 100 })
}

interface DefaultLayoutType {
  readonly isDataLoaded: boolean
  readonly heroElement?: React.ReactNode | null
  readonly children: React.ReactNode
}

export const DefaultLayout = ({ isDataLoaded = true, heroElement = null, children }: DefaultLayoutType) => {
  return (
    // <GoogleReCaptchaProvider reCaptchaKey="6Lf1lFkcAAAAAN_-PA0s_9v_uCrV96xzUmmeMyZL">
    <>
      <GlobalStyles />
      <Helmet
        htmlAttributes={{
          lang: 'de',
        }}
      >
        <meta charSet="utf-8" />
        <title>Hell-O-Chili | Hochwertige Chili Spezialitäten</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta
          name="description"
          content="Hell-O-Chili gegründet 2017, nach einigen Jahren Erfahrung in der Verarbeitung von Chili Schoten. Entstanden aus der Liebe zu scharfen Produkten aus hochwertigen Zutaten. Wir verwenden ausschließlich regionale Produkte, um die heimischen Landwirte zu unterstützen und die Umwelt zu schonen."
        />
      </Helmet>
      <LoadingScreen isVisible={!isDataLoaded} />
      {heroElement}
      <CartProvider>
        <NavBar />
        <CookieLaw isDataLoaded={isDataLoaded} />
        {children}
      </CartProvider>
      <Footer />
    </>
    // </GoogleReCaptchaProvider>
  )
}

DefaultLayout.defaultProps = {
  heroElement: null,
}
