import styled from 'styled-components'

export const ConfirmationBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 3rem;
  @media (min-width: 992px) {
    flex-direction: row;
  }
`

export const ConfirmationBiggerRow = styled.div`
  width: 100%;
  padding-right: 1rem;
  @media (min-width: 992px) {
    flex-basis: 60%;
  }
`
export const ConfirmationRow = styled.div`
  flex-basis: 40%;
`

export const ConfirmationHeading = styled.h2`
  text-transform: uppercase;
  margin-top: 0;
`

export const ConfirmationGif = styled.img`
  width: 100%;
  margin: 1rem 0;
  @media (min-width: 992px) {
    margin: 3rem 0 0;
  }
`

export const ConfirmationOrderRow = styled.div`
  flex-basis: 33%;
`
