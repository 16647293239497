import React from 'react'

export const Sofort = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="50px" height="50px">
    <path
      fill="currentColor"
      d="M13.953 13.879a.27.27 0 01.26-.165h1.337c.01-.016.02-.033.032-.048.282-.4.683-.724 1.058-.953h-2.464c-.69 0-1.202.349-1.444 1.001l-1.17 3.216h1.281l.602-1.653h1.133l.322-.885h-1.13l.183-.513zM7.189 14.35s-.065-.02-.142-.041l-.21-.06a.281.281 0 01-.165-.12.235.235 0 01-.025-.189.277.277 0 01.138-.166.56.56 0 01.27-.06h1.548c.01-.016.02-.033.032-.048.282-.4.625-.724 1-.953H7.217c-.381 0-.752 0-1.084.169-.331.168-.624.505-.85 1.179-.049.145-.074.27-.078.378a.565.565 0 00.057.281c.05.098.133.19.236.268.103.078.226.141.359.181l.16.046.244.072a.495.495 0 01.113.048.2.2 0 01.111.178.285.285 0 01-.016.1.275.275 0 01-.168.187c-.089.036-.22.048-.42.05H3.394L3 16.93h2.663c.251 0 .71 0 1.15-.187.44-.187.862-.561 1.04-1.31.068-.28.047-.51-.063-.69-.11-.18-.31-.31-.601-.393z"
    />
    <path
      fill="currentColor"
      d="M12.01 13.108c-.21-.301-.57-.455-1.073-.455a2.21 2.21 0 00-.945.216c-.427.202-.815.54-1.13.987-.206.29-.382.627-.52 1.004-.26.711-.256 1.288.01 1.668.15.215.462.471 1.098.472h.003c1.158 0 2.023-.76 2.57-2.257.145-.396.336-1.136-.013-1.635zm-1.24 1.694c-.36.99-.623 1.238-1.018 1.238-.385 0-.52-.243-.16-1.228.268-.735.617-1.205 1.038-1.205.4 0 .492.233.14 1.195zM23.011 12.94c.152.22.21.48.175.777h.777l-1.17 3.216h1.169l1.171-3.216h1.502L27 12.716h-4.202c.083.065.154.14.213.224z"
    />
    <path
      fill="currentColor"
      d="M22.74 13.13c-.192-.278-.584-.414-1.197-.414h-1.42l-1.535 4.216h1.304l.558-1.484c.028.061.357 1.484.357 1.484h1.371s-.25-1.045-.433-1.468c-.047-.108-.096-.23-.162-.332.387-.144.949-.453 1.181-1.079.143-.385.135-.696-.024-.924zm-1.135.93c-.125.338-.35.546-.778.546h-.223l.379-1.02h.215c.394 0 .54.116.407.475zM19 13.108c-.21-.301-.57-.455-1.073-.455-.328 0-.646.075-.945.216-.427.202-.814.54-1.13.987-.206.29-.382.627-.52 1.004-.259.711-.256 1.288.01 1.668.15.215.463.471 1.099.472h.002c1.159 0 2.024-.76 2.57-2.257.146-.396.336-1.136-.012-1.635zm-1.24 1.694c-.36.99-.623 1.238-1.018 1.238-.385 0-.52-.243-.16-1.228.269-.735.617-1.205 1.038-1.205.4 0 .492.233.14 1.195z"
    />
    <path
      fill="currentColor"
      d="M28 4H2C.897 4 0 4.897 0 6v18c0 1.103.897 2 2 2h26c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm1 20c0 .551-.448 1-1 1H2c-.552 0-1-.449-1-1V6c0-.551.448-1 1-1h26c.552 0 1 .449 1 1v18z"
    />
  </svg>
)
