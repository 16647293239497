import React from 'react'
import { useWindowScroll } from '@hooks'
import { BackToTopArrow, BackToTopContainer } from './BackToTopStyles'

export const BackToTop = () => {
  const [scroll] = useWindowScroll()
  const onClickHandler = () => window.scrollTo(0, 0)
  return (
    <BackToTopContainer onClick={onClickHandler} isVisible={scroll >= 30}>
      <BackToTopArrow />
    </BackToTopContainer>
  )
}
