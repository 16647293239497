import React, { useContext, useState } from 'react'
import { Heading, Loading } from '@components'
import { CheckoutStateContext } from '@contexts'
import { ArrowUp, ArrowDown } from '@icons'

import { CheckoutSummary } from '../CheckoutSummary'
import { CheckoutShortSummary } from '../CheckoutShortSummary'
import { CheckoutProcess } from '../CheckoutProcess'

import {
  CheckoutOuterBox,
  CheckoutHeader,
  CheckoutSummaryButton,
  CheckoutBox,
  CheckoutInnerProcessBox,
  CheckoutInnerSummaryBox,
} from './CheckoutStyles'

export const Checkout = () => {
  const { loaded } = useContext(CheckoutStateContext)
  const [isMobileSummaryOpen, setIsMobileSummaryOpen] = useState(false)
  return loaded ? (
    <CheckoutOuterBox>
      <CheckoutHeader>
        <Heading title="Checkout" subtitle="" isMargin={false} />
        <CheckoutSummaryButton onClick={() => setIsMobileSummaryOpen(!isMobileSummaryOpen)}>
          {isMobileSummaryOpen ? (
            <>
              <span>Schließen</span>
              <ArrowUp />
            </>
          ) : (
            <>
              <span>Rabattcode & Übersicht</span>
              <ArrowDown />
            </>
          )}
        </CheckoutSummaryButton>
      </CheckoutHeader>
      <CheckoutBox>
        <CheckoutInnerProcessBox>
          <CheckoutProcess />
        </CheckoutInnerProcessBox>
        <CheckoutInnerSummaryBox isMobileOpen={isMobileSummaryOpen}>
          <CheckoutSummary />
        </CheckoutInnerSummaryBox>
        <CheckoutShortSummary />
      </CheckoutBox>
    </CheckoutOuterBox>
  ) : (
    <Loading />
  )
}
