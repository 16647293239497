/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { CustomerDataType, SubdivisionType } from '@types'
import { UseFormRegister } from 'react-hook-form'
import { CheckoutSelect } from '../CheckoutInputStyles'

interface Props {
  readonly register: UseFormRegister<CustomerDataType>
  readonly setRegion: (newRegion: string) => void
  readonly region: string
  readonly country: string
  readonly subdivisions: SubdivisionType
  readonly inputName: 'region' | 'billingRegion'
}

export const CheckoutSubdivisionList = ({ register, setRegion, region, subdivisions, inputName }: Props) => {
  const onChangeHandler = (e: { target: { value: string } }) => {
    setRegion(e.target.value)
  }

  return (
    <>
      <input value={region} type="hidden" {...register(inputName, { required: true })} />
      <CheckoutSelect value={region} onChange={onChangeHandler}>
        {subdivisions.subdivisions &&
          Object.entries(subdivisions.subdivisions).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
      </CheckoutSelect>
    </>
  )
}
