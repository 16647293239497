import styled from 'styled-components'

export const WikiSideBarRoot = styled.a`
  width: fit-content;
  position: relative;
  font-size: 1.3rem;
  margin: 0rem;
  padding: 0rem;
  text-decoration: none;
  transition: width 0.5s;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    height: 1px;
    transition: width 0.5s;
  }

  &:hover:after {
    width: 100%;
  }
`

export const WikiSideBarLinkBox = styled.span`
  display: inline-flex;
  align-items: center;
`

interface WikiSideBarSubLinkBoxProps {
  readonly isOpen: boolean
}

export const WikiSideBarSubLinkBox = styled.div<WikiSideBarSubLinkBoxProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  @media screen and (min-width: 992px) {
    display: flex;
  }
`

export const WikiSideBarLink = styled(WikiSideBarRoot)`
  font-weight: bold;
  font-size: 1.5em;
  &:after {
    background: #880606;
  }
`

export const WikiSideBarSubLink = styled(WikiSideBarRoot)`
  margin-left: 1rem;
  color: #212121;
  &:after {
    background: black;
  }
  @media screen and (min-width: 992px) {
    margin-left: 0.7rem;
  }
`

export const InvisibleButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin-left: 1rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: inline-flex;
  align-items: center;
  @media screen and (min-width: 992px) {
    display: none;
  }
`
