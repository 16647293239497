import styled from 'styled-components'

interface Props {
  readonly isVisible: boolean
}

export const NavBarOffScreenBox = styled.div<Props>`
  position: absolute;
  top: 100%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 103;
  display: ${({ isVisible }) => !isVisible && 'none'};
  @media (min-width: 768px) {
    display: none;
  }
`
