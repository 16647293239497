import { Link } from 'gatsby'
import styled from 'styled-components'

export const NavBarLogoBox = styled(Link)`
  transition: all 0.3s;
  margin: 0.5rem 1rem;
  max-width: 200px;
  @media (min-width: 768px) {
    margin: 0.5rem 0rem;
    max-width: 300px;
  }
`

export const NavBarLogoImage = styled.img`
  width: 100%;
  max-width: 200px;
`
