import React, { Fragment } from 'react'
import { WikiType } from '@types'

import { WikiContentHeading, WikiContentSubHeading } from './WikiContentStyles'
import { renderText } from './renderText'

interface Props {
  readonly category: string
  readonly wikiItems: Array<WikiType>
}

export const WikiContentItem = ({ category, wikiItems }: Props) => (
  <>
    {category && <WikiContentHeading id={category}>{category}</WikiContentHeading>}
    {wikiItems.map(({ text, allTextImages, sub_category }: WikiType, index) => (
      <Fragment key={String(index)}>
        {sub_category && (
          <WikiContentSubHeading id={sub_category ? `${category}_${sub_category}` : ''}>
            {sub_category}
          </WikiContentSubHeading>
        )}
        {renderText(text, allTextImages)}
      </Fragment>
    ))}
  </>
)
