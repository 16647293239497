import styled from 'styled-components'

export const WikiBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
  padding-bottom: 3rem;
`

export const WikiContentBox = styled.div`
  flex-basis: 80%;
  overflow: hidden;
`

export const WikiSidebarBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  /* height: 100%; */
  @media screen and (min-width: 992px) {
    /* border-left: 1px solid #5d5d5d; */
    margin-left: 2rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`
