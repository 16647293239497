/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  DeepMap,
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'

import { CustomerDataType } from '@types'

import { useCountryAndRegion } from '@hooks'
import { CheckoutCountryList } from '../CheckoutCountryList'
import { CheckoutSubdivisionList } from '../CheckoutSubdivisionList'
import { CheckoutError, CheckoutSearch, CheckoutInput, CheckoutInputRow } from '../CheckoutInputStyles'

import { CheckoutShippingSelector } from './CheckoutShippingSelector'
import { CheckoutSpacer } from '../../CheckoutPaymentPage/CheckoutPaymentPageStyles'

interface Props {
  readonly register: UseFormRegister<CustomerDataType>
  readonly setError: UseFormSetError<CustomerDataType>
  readonly setValue: UseFormSetValue<CustomerDataType>
  readonly clearErrors: UseFormClearErrors<CustomerDataType>
  readonly errors: DeepMap<CustomerDataType, FieldError>
}

export const CheckoutShippingInfo = ({ register, setError, setValue, errors, clearErrors }: Props) => {
  const [countries, subdivisions, country, setCountry, region, setRegion] = useCountryAndRegion(
    'street',
    'postal_zip_code',
    'town_city',
    'country',
    'region',
    'geoshipping',
    clearErrors,
    setError,
    setValue,
  )

  return (
    <>
      <CheckoutInput isError={!!errors.name} placeholder="Name" {...register('name', { required: true })} />
      <CheckoutSearch id="geoshipping" />
      <CheckoutError isVisible={errors.country?.type === 'validate'}>
        Dieses Land kann leider nicht beliefert werden
      </CheckoutError>
      <CheckoutError isVisible={errors.region?.type === 'validate'}>
        Diese Region kann leider nicht beliefert werden
      </CheckoutError>
      <CheckoutSpacer>
        <hr />
        <p>oder manuell eingeben</p>
      </CheckoutSpacer>
      <CheckoutInput isError={!!errors.street} placeholder="Adresse" {...register('street', { required: true })} />
      <CheckoutInputRow>
        <CheckoutInput
          type="number"
          isError={!!errors.postal_zip_code}
          placeholder="Postleitzahl"
          {...register('postal_zip_code', { required: true })}
        />
        <CheckoutInput
          isError={!!errors.town_city}
          placeholder="Stadt"
          {...register('town_city', { required: true, pattern: /^[A-Za-z]+$/i })}
        />
      </CheckoutInputRow>
      <CheckoutError isVisible={errors.postal_zip_code?.type === 'pattern'}>
        Die Postleitzahl ist unvollständig.
      </CheckoutError>
      <CheckoutSubdivisionList
        subdivisions={subdivisions}
        country={country}
        region={region}
        setRegion={setRegion}
        register={register}
        inputName="region"
      />
      <CheckoutCountryList
        countries={countries}
        country={country}
        setCountry={setCountry}
        register={register}
        inputName="country"
      />
      <CheckoutShippingSelector register={register} country={country} region={region} />
    </>
  )
}
