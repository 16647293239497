import { commerce } from '../../commerce'

export const fetchVariantsByProduct = (productId) => {
  return commerce.products
    .getVariants(productId)
    .then((variants) => {
      return variants.data
    })
    .catch((error) => {
      console.log('There was an error retrieving the product', error)
      return {}
    })
}
