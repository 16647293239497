import styled, { keyframes } from 'styled-components'

const scaling = keyframes`
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
`

interface LoadingScreenWrapperProps {
  isVisible: boolean
}

export const LoadingScreenWrapper = styled.div<LoadingScreenWrapperProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
  svg {
    animation: ${scaling} 2s ease infinite;
  }
`
