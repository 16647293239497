import { CardElement, IbanElement } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { CheckoutMethodType, CustomerDataType } from '@types'

export const getPaymentMethodResponse = (
  checkoutMethod: CheckoutMethodType,
  stripe: Stripe,
  elements: StripeElements,
  data: CustomerDataType,
  sofort: { name: string; country: string },
) => {
  switch (checkoutMethod) {
    default:
    case 'card':
      return stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
      })
    case 'sofort':
      return stripe.createPaymentMethod({
        type: 'sofort',
        sofort: {
          country: sofort.country,
        },
        billing_details: {
          name: sofort.name,
        },
      })
    // case 'iban':
    //   return stripe.createPaymentMethod({
    //     type: 'sepa_debit',
    //     sepa_debit: elements.getElement(IbanElement)!,
    //     billing_details: {
    //       name: `${data.firstName} ${data.lastName}`,
    //       email: data.email,
    //     },
    //   })
  }
}
