import styled from 'styled-components'

export const CheckoutShortSummaryBox = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    display: none;
  }
`

export const CheckoutShortSummaryImage = styled.div`
  width: 40vw;
  height: 40vw;
  background-position: center;
  background-size: 100%, auto;
  background-repeat: no-repeat;
  margin-right: 2rem;
`

export const CheckoutShortSummaryInformation = styled.div``

export const CheckoutShortSummarySubHeading = styled.h3`
  margin: 0;
`
export const CheckoutShortSummaryTotal = styled.h2`
  margin: 0;
`
