import React, { useContext, useEffect, useState } from 'react'
import { CartStateContext, CartDispatchContext, addToCart } from '@contexts'
import { formatPrice } from '@helper'
import { ProductType, VariantType } from '@types'

import { ContactFormPopUp, QuantitySelector } from '@components'
import { filterDescription } from './filterDescription'

import { ProductDetailGallery } from './ProductDetailGallery'
import { ProductDetailButton } from './ProductDetailButton'
import { ProductDetailVariants } from './ProductDetailVariants'

import {
  ProductDetailBox,
  ProductDetailDescription,
  ProductDetailHeading,
  ProductDetailSubHeading,
  ProductDetailPrice,
  ProductDetailProduct,
} from './ProductDetailStyles'

import { getCurrentMaxAmount } from './getCurrentMaxAmount'
import { checkAvailabilty } from './checkAvailabilty'

interface Props {
  readonly product: ProductType
  readonly variants: Array<VariantType>
}

export const ProductDetail = ({ product, variants }: Props) => {
  const { cart, adding } = useContext(CartStateContext)
  const dispatch = useContext(CartDispatchContext)

  const [isVariantAlert, setIsVariantAlert] = useState({
    alert: false,
    message: '',
  })
  const [isPopUpVisible, setIsPopUpVisible] = useState(false)
  const [amount, setAmount] = useState(1)
  const [selectedVariant, setSelectedVariant] = useState({} as VariantType | null)

  useEffect(() => {
    if (!checkAvailabilty(product, variants)) {
      setIsVariantAlert({
        alert: true,
        message: 'Benachrichtige mich wenn das Produkt wieder verfügbar ist',
      })
    }
  }, [])

  const existVariants = product.variant_groups.length > 0
  const isSelectedVariant = !!(selectedVariant && selectedVariant.id)

  const currentMaxAmount = getCurrentMaxAmount(cart, product, existVariants, selectedVariant)
  const isButtonActive = existVariants ? isSelectedVariant && currentMaxAmount > 0 : currentMaxAmount > 0

  const onSubmit = () => {
    if (existVariants && isSelectedVariant) {
      addToCart(dispatch, product.id, amount, selectedVariant.id)
    } else {
      addToCart(dispatch, product.id, amount, '')
    }
  }

  return (
    <>
      <ContactFormPopUp
        isVisible={isPopUpVisible}
        onClickHandler={() => setIsPopUpVisible(false)}
        productName={product.name}
      />
      <ProductDetailBox>
        <ProductDetailGallery product={product} variant={selectedVariant} />
        <ProductDetailProduct>
          <ProductDetailHeading>{product.name}</ProductDetailHeading>
          <ProductDetailPrice>{`${formatPrice(selectedVariant?.price?.raw ?? product.price.raw)}`}</ProductDetailPrice>
          <ProductDetailVariants
            variants={variants}
            variantGroups={product.variant_groups}
            setSelectedVariant={setSelectedVariant}
          />
          <QuantitySelector
            amount={amount}
            setAmount={setAmount}
            maxAmount={currentMaxAmount}
            isAlert={isVariantAlert}
            onAlertClickHandler={() => setIsPopUpVisible(true)}
          />
          <ProductDetailButton isUpdated={adding} isActive={isButtonActive} onClickHandler={onSubmit} />
        </ProductDetailProduct>
      </ProductDetailBox>
      <ProductDetailSubHeading>Scharfe Infos</ProductDetailSubHeading>
      <ProductDetailDescription dangerouslySetInnerHTML={{ __html: filterDescription(product.description) }} />
    </>
  )
}
