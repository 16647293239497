import styled from 'styled-components'

export const ShoppingCartItemRow = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid white;
  padding: 1rem 0;
`

export const ShoppingCartItemImageBox = styled.div`
  min-width: 33%;
  margin-right: 1rem;
  height: auto;
`

export const ShoppingCartItemImageWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 100%; // 1:1
  position: relative;
`

export const ShoppingCartItemImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

export const ShoppingCartItemInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 1rem;
`

export const ShoppingCartHeaderRow = styled.div`
  display: flex;
`

export const ShoppingCartItemName = styled.h3`
  text-align: left;
  margin-top: 0;
`

export const ShoppingCartItemQuantity = styled.div`
  background-position: center;
  background-size: 100%;
`

export const ShoppingCartItemConfigBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ShoppingCartItemOptionBox = styled.div`
  text-align: left;
`

export const ShoppingCartDeleteButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
