import { LoadingCircle } from '@icons'
import React from 'react'

import { CheckoutSubmitButtonBox } from './CheckoutSubmitButtonStyles'

interface Props {
  readonly onClickHandler: (() => Promise<void>) | undefined
  readonly isLoading: boolean
  readonly isDisabled: boolean
}

export const CheckoutSubmitButton = ({ onClickHandler, isLoading, isDisabled }: Props) => {
  return (
    <CheckoutSubmitButtonBox onClick={onClickHandler} disabled={isDisabled} isLoading={isLoading}>
      {isLoading ? <LoadingCircle color="white" height="75px" width="75px" /> : `Kostenpflichtig bestellen`}
    </CheckoutSubmitButtonBox>
  )
}
