import React from 'react'
import { WikiType } from '@types'

import { BackToTop } from './BackToTop'
import { WikiBox, WikiContentBox, WikiSidebarBox } from './WikiStyles'
import { convertWikiData } from './convertWikiData'
import { WikiContentItem } from './WikiContentItem'
import { WikiSideBarItem } from './WikiSideBarItem'

interface Props {
  readonly data: Array<WikiType>
}

export const Wiki = ({ data }: Props) => {
  const description = data.find((wikiItem) => wikiItem.category === null && wikiItem.sub_category === null)
  const convertedData = convertWikiData(data)
  return (
    <>
      {description && <WikiContentItem category="" wikiItems={[description]} />}
      <WikiBox>
        <WikiContentBox>
          {convertedData.map(([category, wikiItems]) => (
            <WikiContentItem key={category} category={category} wikiItems={wikiItems} />
          ))}
        </WikiContentBox>
        <WikiSidebarBox>
          {convertedData.map(([category, wikiItems]) => (
            <WikiSideBarItem key={category} category={category} wikiItems={wikiItems} />
          ))}
        </WikiSidebarBox>
        <BackToTop />
      </WikiBox>
    </>
  )
}
