import React, { useState } from 'react'
import { Link } from 'gatsby'
// import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Alert } from '../Alert'
import { Button } from '../Button'

import {
  ContactFormBox,
  ContactFormRow,
  ContactInput,
  ContactTextArea,
  ContactFormButtonRow,
  ContactFormLabel,
} from './ContactFormStyles'
import { Heading } from '../Heading'

// TODO: ContactForm überprüfen

interface Props {
  readonly title: string
  readonly subtitle: string
}

export const ContactForm = ({ title, subtitle }: Props) => {
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(true)
  const [messageState, setMessageState] = useState(false)
  const [messageContent, setMessageContent] = useState('')
  const [messageSuccess, setMessageSuccess] = useState(true)

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)
    fetch('https://mailer.hellochili.de', {
      method: 'POST',
      // headers: { 'content-type': 'application/json' },
      mode: 'cors',
      body: data,
    })
      .then((res) => {
        if (res.ok) {
          return res.text()
        }
        throw new Error('Ein Fehler ist aufgetreten')
      })
      .then((text: string) => {
        setMessageSuccess(true)
        setMessageState(true)
        setMessageContent(text)
      })
      .catch(() => {
        setMessageSuccess(false)
        setMessageState(true)
        setMessageContent('Ein Fehler ist aufgetreten')
      })

    // fakeFulfillment()
    // fakeRejection()
    setTimeout(() => setMessageState(false), 5000)
  }

  const fakeFulfillment = () => {
    setMessageSuccess(true)
    setMessageState(true)
    setMessageContent('Die Nachricht ist angekommen. Vielen Dank!')
  }

  const fakeRejection = () => {
    setMessageSuccess(false)
    setMessageState(true)
    setMessageContent(
      'Die Nachricht ist nicht angekommen. Bitte versuchen Sie es später erneut oder nutzen Sie unsere anderen Kontaktmöglichkeiten!',
    )
  }

  return (
    <ContactFormBox>
      {/* <GoogleReCaptcha onVerify={() => setIsSendButtonEnabled(true)} /> */}
      <Heading id="contact" title={title} subtitle={subtitle} />
      <form onSubmit={handleSubmit}>
        <ContactFormRow>
          <ContactInput id="name" name="name" type="text" placeholder="Vor- und Nachname" required />
        </ContactFormRow>
        <ContactFormRow>
          <ContactInput id="email" name="email" type="email" placeholder="maxmusterman@example.com" required />
        </ContactFormRow>
        <ContactFormRow>
          <ContactTextArea
            id="message"
            name="message"
            rows={4}
            placeholder="Hier können Sie Ihre Nachricht eingeben"
            required
          />
        </ContactFormRow>
        <ContactFormLabel>
          <input style={{ marginRight: '0.6rem' }} id="datenschutz" name="datenschutz" type="checkbox" required />
          Ich habe die <Link to="datenschutz">Datenschutzerklärung</Link> gelesen und akzeptiert
        </ContactFormLabel>
        <ContactFormButtonRow isFlex>
          {isSendButtonEnabled && <Button>Abschicken</Button>}
          <Alert isVisible={messageState} isSuccess={messageSuccess} message={messageContent} />
        </ContactFormButtonRow>
      </form>
    </ContactFormBox>
  )
}
