import { useState, useEffect } from 'react'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { filterSearchResult } from '@helper'
import { accessToken } from '@model'
import { AdressSearchType, GeocodeResultType } from '@types'

export const useAutoComplete = (elementId: string): [AdressSearchType] => {
  const [adressObject, setAdressObject] = useState({
    street: '',
    postal_zip_code: '',
    town_city: '',
    country: '',
    region: '',
  } as AdressSearchType)

  useEffect(() => {
    const element = document.getElementById(elementId)
    if (element && !element.firstChild) {
      const geocoder = new MapboxGeocoder({
        limit: 5,
        accessToken,
        placeholder: 'Suche ...',
        countries: 'DE',
      })
      geocoder.on('result', ({ result }: GeocodeResultType) => {
        if (result.context.length > 0) {
          setAdressObject(filterSearchResult(result.address, result.text, result.context))
        }
      })
      geocoder.addTo(`#${elementId}`)
    }
  }, [])

  return [adressObject]
}
