import styled from 'styled-components'
import 'flickity/css/flickity.css'
import { Link } from 'gatsby'

export const SliderBox = styled.div`
  margin: 3rem 0;
  min-height: 400px;
`

export const SliderSlide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  padding: 4rem;
`

export const SliderImage = styled.div`
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  @media screen and (min-width: 768px) {
    background-position: center;
    background-size: 100%;
  }
`

export const SliderOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const SliderText = styled.div`
  color: white;
  font-size: 1.4rem;
  z-index: 6;
  text-decoration: none;
  color: #bfbbb0;
  max-width: 800px;
`

export const SliderCTA = styled(Link)`
  margin-top: 2rem;
  background-color: black;
  color: #bfbbb0;
  text-decoration: none;
  padding: 1rem 2rem;
  z-index: 6;
  border-radius: 5px;
`

export const ArrowLeft = styled.div`
  position: absolute;
`
export const ArrowRight = styled.div`
  position: absolute;
`
