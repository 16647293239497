import styled from 'styled-components'

export const ContactFormBox = styled.div`
  padding-bottom: 3rem;
  margin-top: 2rem;
`

interface ContactFormRowProps {
  isFlex?: boolean
  isLast?: boolean
}

export const ContactFormRow = styled.label<ContactFormRowProps>`
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  min-height: ${(props) => (props.isFlex ? '4.5rem' : '0')};
  .alert {
    margin-bottom: 0;
  }
  &:last-child {
    margin: 0;
  }
`

export const ContactFormLabel = styled.label`
  font-size: 1.2rem;
  flex: 1;
  align-items: center;
`

export const ContactFormButtonRow = styled.div<ContactFormRowProps>`
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};
  width: 100%;
  margin-bottom: ${(props) => (props.isLast ? '0' : '1rem')};
  align-items: center;
  justify-content: space-between;
  padding-top: ${(props) => (props.isFlex ? '2rem' : '0')};
  .alert {
    margin-bottom: 0;
  }
  &:last-child {
    margin: 0;
  }
`

export const ContactFormText = styled.h1`
  text-transform: uppercase;
  white-space: pre-line;
`

export const ContactInput = styled.input`
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ccc;
`

export const ContactFileInput = styled.input`
  margin-top: 0.2rem;
  width: 100%;
`

export const ContactTextArea = styled.textarea`
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ccc;
  resize: none;
`
