import { commerce } from '../../commerce'

export const fetchShippingSubdivisionsOfCountry = (checkoutId, country) => {
  return commerce.services
    .localeListShippingSubdivisions(checkoutId, country)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return {}
    })
}
