import { commerce } from '../../commerce'

export const fetchProducts = () => {
  return commerce.products
    .list()
    .then((products) => {
      return products.data
    })
    .catch((error) => {
      console.log('There was an error fetching the products', error)
      return []
    })
}
