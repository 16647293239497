import React from 'react'

export const Chili = () => (
  <svg viewBox="0 0 100 100" width="100px" height="100px">
    <g>
      <path
        fill="#880606"
        d="M78.4 41.7c-1.1 0-2.1-.6-2.6-1.7-.6-1.3-1.4-2.3-2.5-3.2-1 1.6-1.5 2.5-1.7 2.7-.5.8-1.4 1.3-2.4 1.3-2.5 0-3-2.5-3.4-4.6-.1-.3-.1-.6-.2-.8-1 .3-2 .8-2.8 1.5-.5.4-1.1.6-1.8.6-.4 0-.9-.1-1.2-.3-.3-.2-.6-.4-.8-.6-.7.8-1.4 1.7-1.9 2.6-.4.7-.7 1.5-.9 2.3-.4 1.3-.5 2.6-.4 3.9.1 1.2-.1 2.4-.4 3.6-6.1 21.4-25 35.8-46 37-1.4.1-1.8 2-.4 2.5 4.6 1.9 9.7 3.1 15.1 3.6 1.4.1 2.7.2 4.1.2 22.9 0 43.2-15.8 49.5-38.1l1.7-6.2c.6-2.3.6-4.6 0-6.7-.4.3-.7.4-1 .4z"
      />
      <path
        fill="#880606"
        d="M89.6 7.5c-8.9.2-19.7 8.9-20.8 18.8-2.9.4-5.5 2.3-6.8 5l-.6 1.5c0 .1-.1.2-.1.4-.1.6-.2 1.1-.2 1.7 1.9-1.6 4.3-2.5 6.9-2.5 0 .2.1.4.1.7.3 1.8.9 5.2 1.2 5.2h.1c.3-.4 1.6-2.6 2.7-4.3.1-.2.3-.5.4-.7 2.7 1.1 4.9 3.2 6 5.8.2-.4.3-.8.4-1.2v-.2c.2-.7.3-1.3.3-2 .1-3.8-2.1-7.3-5.7-8.7.8-7.3 9.7-14.5 16.2-14.7 1.3 0 2.3-1.1 2.3-2.4-.1-1.4-1.2-2.4-2.4-2.4z"
      />
    </g>
  </svg>
)
