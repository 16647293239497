import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

export const FooterBox = styled.div`
  border-top: 3px solid #880606;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 5vw 1rem;
  background-color: black;
  color: white;
  font-family: 'Roboto', sans-serif;
  @media screen and (min-width: 768px) {
    height: 160px;
    flex-direction: row;
  }
`
export const FooterCopyright = styled.div`
  padding: 0rem 5vw;
  background-color: rgb(237, 237, 237);
  // color: white;
`

export const FooterTextBox = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FooterLink = styled.li`
  &::before {
    content: ' ';
    border: 1px solid #880606;
    background-color: #880606;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin-left: -1em;
    margin-right: 0.4rem;
  }
`

export const FooterLinks = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: column;
  line-height: 1.34;
  a {
    color: white;
    text-decoration: none;
  }
  list-style: none;
`

export const FooterAdress = styled.div`
  white-space: pre-line;
  @media screen and (min-width: 768px) {
    margin-right: 10rem;
  }
  p {
    margin: 0;
  }
  strong {
    font-weight: bold;
  }
`

export const FooterImageBox = styled.div`
  margin-top: 1rem;
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`

export const FooterImage = styled(GatsbyImage)`
  width: 150px;
  height: 100px;
`
