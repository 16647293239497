import React from 'react'
import { ImageType } from '@types'

import { Paragraph } from '../Paragraph'
import { Heading } from '../Heading'

import { AboutUsBox, AboutUsInnerBox, AboutMeImage } from './AboutMeStyles'

interface Props {
  readonly text: string
  readonly subtitle: string
  readonly title: string
  readonly image_text: string
  readonly image: ImageType
}

export const AboutMe = ({ title, subtitle, text, image_text, image }: Props) => (
  <>
    <Heading id="aboutus" title={title} subtitle={subtitle} />
    <Paragraph>{text}</Paragraph>
    <AboutUsBox>
      <AboutMeImage image={image.mediaUrl.childImageSharp.gatsbyImageData} alt="Über mich" />
      <AboutUsInnerBox>
        <Paragraph>{image_text}</Paragraph>
      </AboutUsInnerBox>
    </AboutUsBox>
  </>
)
