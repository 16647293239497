import styled from 'styled-components'

interface QuantitySelectorWrapperProps {
  readonly isInline: boolean
}

export const QuantitySelectorWrapper = styled.div<QuantitySelectorWrapperProps>`
  display: ${({ isInline }) => (isInline ? 'flex' : 'block')};
  align-items: center;
`

export const QuantitySelectorHeader = styled.h3`
  text-transform: uppercase;
  margin-right: 0.5rem;
`

export const QuantitySelectorBox = styled.div`
  margin: 1rem 1rem 1rem 0;
  display: flex;
  width: 100%;
`

interface QuantitySelectorSelectProps {
  readonly isEnabled: boolean
}

export const QuantitySelectorSelect = styled.select<QuantitySelectorSelectProps>`
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;
  background-color: white;
  border-radius: 4px;
  font-size: 0.875rem;
  border: 2px solid black;
  min-width: 55px;
  opacity: ${({ isEnabled }) => (isEnabled ? '1' : '0.3')};
  margin-right: 1rem;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%);
  background-position: calc(100% - 12px) 50%, calc(100% - 7px) 50%;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
`

export const QuantitySelectorButton = styled.div`
  padding: 0.4rem;
  font-size: 0.875rem;
  background-color: white;
  border: 2px solid black;
  cursor: pointer;
`
