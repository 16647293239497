import React from 'react'

import { CheckoutSelect } from '../../CheckoutCustomerPage/CheckoutInputStyles'

import { sofortCountrys } from './getSofortCountries'

interface Props {
  readonly defaultValue: string
  readonly sofort: { name: string; country: string }
  readonly setSofort: (newSofort: { name: string; country: string }) => void
}

export const CheckoutSofortCountryList = ({ defaultValue, sofort, setSofort }: Props) => (
  <CheckoutSelect id="sofortCountry">
    {Object.entries(sofortCountrys).map(([key, value]) => (
      <option
        key={key}
        value={key}
        selected={defaultValue in sofortCountrys && defaultValue === key}
        onChange={(e) => setSofort({ ...sofort, country: e.target.value })}
      >
        {value}
      </option>
    ))}
  </CheckoutSelect>
)
