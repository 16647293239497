import styled from 'styled-components'

interface ContainerProps {
  readonly fullHeight?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem 0 1rem;
  min-height: ${({ fullHeight }) => (fullHeight ? '100vh' : '0')};
  @media (min-width: 567px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`
