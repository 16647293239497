import React from 'react'
import { ProductType } from '@types'
import { sortProductsBySku } from '@helper'

import { Heading } from '../../LayoutComponents'

import { ProductListItem } from './ProductListItem'
import { ProductItemListBox } from './ProductListStyles'

interface Props {
  readonly title: string
  readonly subtitle: string
  readonly products: Array<ProductType>
}

export const ProductList = ({ title, subtitle, products }: Props) => (
  <>
    <Heading title={title} subtitle={subtitle} />
    <ProductItemListBox>
      {products.sort(sortProductsBySku).map((product, index) => (
        <ProductListItem key={product.id} product={product} index={index} />
      ))}
    </ProductItemListBox>
  </>
)
