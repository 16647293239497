import styled from 'styled-components'

interface BackToTopProps {
  isVisible: boolean
}

export const BackToTopContainer = styled.div<BackToTopProps>`
  transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(100%)')};
  transition-duration: 0.5s;
  position: fixed;
  bottom: 0;
  width: 50px;
  height: 50px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-bottom: none;
  cursor: pointer;
  z-index: 99;
`

export const BackToTopArrow = styled.div`
  width: 20px;
  height: 20px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(-45deg);
`
