import { groupBy } from 'lodash'
import { WikiType, FormatedWikiType } from '@types'

const filterFormatedWikiItems = (item: FormatedWikiType) => item[0] !== 'null'

const compareFormatedWikiItemsByCategory = (
  formatedWikiItem1: FormatedWikiType,
  formatedWikiItem2: FormatedWikiType,
) => {
  if (formatedWikiItem1[0] < formatedWikiItem2[0]) return -1
  if (formatedWikiItem1[0] > formatedWikiItem2[0]) return 1
  return 0
}

const compareWikiItemsBySubCategory = (wikiItem1: WikiType, wikiItem2: WikiType) => {
  if (wikiItem1.sub_category < wikiItem2.sub_category) return -1
  if (wikiItem1.sub_category > wikiItem2.sub_category) return 1
  return 0
}

export const convertWikiData = (data: Array<WikiType>): Array<FormatedWikiType> =>
  Object.entries(groupBy(data, (wikiItem) => wikiItem.category))
    .filter(filterFormatedWikiItems)
    .sort(compareFormatedWikiItemsByCategory)
    .map(([category, wikiItems]) => [category, wikiItems.sort(compareWikiItemsBySubCategory)])
