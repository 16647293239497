import styled, { css, keyframes } from 'styled-components'

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

export const ShoppingCartImage = styled.img`
  position: relative;
  width: 40px;
  height: auto;
`

export const RelativeBox = styled.span`
  position: relative;
  cursor: pointer;
  margin-right: 1.5rem;
  @media (min-width: 768px) {
    margin-right: 0;
  }
`

interface ShoppingCartItemsAmountProps {
  playShakeAnimation: boolean
}

export const ShoppingCartItemsAmount = styled.div<ShoppingCartItemsAmountProps>`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #880606;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 50%;
  animation: ${({ playShakeAnimation }) =>
    playShakeAnimation &&
    css`
      ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
    `};
`
