import React from 'react'

interface Props {
  readonly customerReference: string
}

export const ConfirmationOrderDetails = ({ customerReference }: Props) => (
  <>
    <div>
      <span>Deine Bestellung mit der ID </span>
      <span>
        <strong>{customerReference} </strong>
      </span>
      <span>befindet sich auf dem Weg zu dir.</span>
    </div>
    <div>
      <span>Falls du Fragen zu deiner Bestellung hast, </span>
      <a href={`mailto:info@hellochili.de?subject=Bestellung:%20${customerReference}`}>kontaktiere uns</a>
      <span>.</span>
    </div>
  </>
)
