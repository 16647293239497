import React from 'react'
import { TrashContainer, TrashBody, TrashHead } from './TrashStyles'

interface Props {
  readonly isOpen: boolean
}

export const Trash = ({ isOpen }: Props) => (
  <TrashContainer isOpen={isOpen}>
    <TrashHead />
    <TrashBody />
  </TrashContainer>
)
