import { CartType } from '@types'
import { commerce } from '../../../commerce'

import { CartAction } from '../types'

export const deleteFromCart = (dispatch: React.Dispatch<CartAction>, productId: string) => {
  dispatch({ type: 'UPDATING' })
  commerce.cart.remove(productId).then((res: { cart: CartType }) => {
    dispatch({
      type: 'FINISHED',
      payload: {
        cart: res.cart,
      },
    })
  })
}
