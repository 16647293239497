import React, { useContext, useEffect, useState } from 'react'
import { CartStateContext } from '@contexts'
import { ShoppingCartIcon as ShoppingCartImageIcon } from '@icons'
import { isPathVaildForShoppingCart } from '@helper'

import { RelativeBox, ShoppingCartItemsAmount } from './styles'

interface Props {
  readonly handleVisible: () => void
}

export const ShoppingCartIcon = ({ handleVisible }: Props) => {
  const { cart, adding, loaded } = useContext(CartStateContext)
  const [shakeAnimationBuffer, setEndAnimationBuffer] = useState(false)

  useEffect(() => {
    if (loaded && adding) {
      setEndAnimationBuffer(true)
    }
  })

  const hasCartUniqueItems = loaded && cart.total_unique_items > 0

  const renderItemsAmount = () => (
    <ShoppingCartItemsAmount
      playShakeAnimation={shakeAnimationBuffer}
      onAnimationEnd={() => setEndAnimationBuffer(false)}
    >
      {cart.total_unique_items}
    </ShoppingCartItemsAmount>
  )

  return !isPathVaildForShoppingCart() ? (
    <RelativeBox onClick={handleVisible}>
      <ShoppingCartImageIcon width="50px" height="50px" />
      {hasCartUniqueItems ? renderItemsAmount() : null}
    </RelativeBox>
  ) : null
}
