import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { TransformedImageType } from '@types'

import { WikiContentContent } from './WikiContentStyles'

export const renderText = (text: string, allTextImages: Array<TransformedImageType>) => {
  const textStrings = text.split(/<img([\w\W]+?)>/g)
  return textStrings.map((textPart, index) => {
    if (textPart.startsWith('<')) {
      return <WikiContentContent key={String(index)} dangerouslySetInnerHTML={{ __html: textPart }} />
    }
    const image = allTextImages.find((imageObj) => textPart.includes(imageObj.name))
    if (image) {
      return <GatsbyImage key={String(index)} image={image.childImageSharp.gatsbyImageData} alt="Wiki Image" />
    }
    return null
  })
}
