import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { Button } from '../Button'

import { CookieLawBox, CookieText } from './CookieLawStyles'

interface Props {
  isDataLoaded: boolean
}

export const CookieLaw = ({ isDataLoaded }: Props) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const setVisibilty = () => {
      setTimeout(() => {
        const cookie = new Cookies()
        setVisible(cookie.get('cookieLaw') === undefined)
      }, 3000)
    }
    if (isDataLoaded) {
      setVisibilty()
    }
  }, [isDataLoaded])

  const acceptCookieLaw = () => {
    const cookie = new Cookies()
    setVisible(false)
    cookie.set('cookieLaw', 'true')
  }

  return (
    <CookieLawBox visible={visible}>
      <CookieText>
        <span>
          Wir mögen zwar Cookies aber zu viele müssen es auch nicht sein, hier soll es schließlich um Chilis gehen.
          Deshalb verwendet diese Website nur essenzielle und technisch notwendige Cookies. Mehr Informationen hierzu
          findest du in unserer{' '}
        </span>
        <AnchorLink to="/datenschutz#cookies">Datenschutzerklärung</AnchorLink>
        <span>. Mit der Benutzung der Website stimmst du diesen Bedingungen zu.</span>
      </CookieText>
      <Button onClick={() => acceptCookieLaw()}>Okay</Button>
    </CookieLawBox>
  )
}
