/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form'
import { CustomerDataType } from '@types'

import { CheckoutError, CheckoutInput, CheckoutInputRow } from '../CheckoutInputStyles'

interface Props {
  readonly register: UseFormRegister<CustomerDataType>
  readonly errors: DeepMap<CustomerDataType, FieldError>
}

export const CheckoutCustomerInfo = ({ register, errors }: Props) => (
  <>
    <CheckoutInputRow>
      <CheckoutInput
        isError={!!errors.firstName}
        placeholder="Vorname"
        {...register('firstName', { required: true, pattern: /^[A-Za-z]+$/i })}
      />
      <CheckoutInput
        isError={!!errors.lastName}
        placeholder="Nachname"
        {...register('lastName', { required: true, pattern: /^[A-Za-z]+$/i })}
      />
    </CheckoutInputRow>
    <CheckoutInput
      isError={!!errors.email}
      placeholder="E-Mail"
      {...register('email', {
        required: true,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      })}
    />
    <CheckoutError isVisible={errors.email?.type === 'pattern'}>Die E-Mail-Adresse ist unvollständig.</CheckoutError>
  </>
)
