import styled from 'styled-components'

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    max-width: 51.38%;
    margin-right: 3rem;
    margin-bottom: 0;
  }
`
