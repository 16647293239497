import { commerce } from '../../commerce'

export const fetchShippingCountries = (checkoutId) => {
  return commerce.services
    .localeListShippingCountries(checkoutId)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log('There was an error confirming your order', error)
      return {}
    })
}
