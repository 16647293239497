import React, { useContext } from 'react'
import { CartStateContext } from '@contexts'
import { ItemType } from '@types'
import { isPathVaildForShoppingCart } from '@helper'

import { Trash } from '@icons'
import { ShoppingCartItem } from './ShoppingCartItem'
import {
  ShoppingCartBox,
  ShoppingCartItemBox,
  ShoppingCartSubtotal,
  ShoppingCartOrderButton,
} from './ShoppingCartStyles'

interface Props {
  readonly isOpen: boolean
}

export const ShoppingCart = ({ isOpen }: Props) => {
  const { cart, loaded } = useContext(CartStateContext)
  const isCartFilled = loaded && cart.line_items.length > 0

  return !isPathVaildForShoppingCart() && loaded ? (
    <ShoppingCartBox isOpen={isOpen}>
      <ShoppingCartItemBox>
        {isCartFilled && cart.line_items.map((item: ItemType) => <ShoppingCartItem key={item.id} item={item} />)}
      </ShoppingCartItemBox>
      <ShoppingCartSubtotal>{`Summe: ${cart.subtotal.formatted_with_code}`}</ShoppingCartSubtotal>
      <ShoppingCartOrderButton to={isCartFilled ? '/checkout' : '/'} $isLinkEnabled={isCartFilled}>
        Bestellen
      </ShoppingCartOrderButton>
    </ShoppingCartBox>
  ) : null
}
