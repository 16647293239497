import styled from 'styled-components'

export const CheckoutDiscountRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`

export const CheckoutDiscountButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0.2rem 0.4rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border: 1px solid black;
  border-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`
