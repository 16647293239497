import React from 'react'
import { AdressType } from '@types'

interface Props {
  readonly adress: AdressType
}

export const ConfirmationAdressBox = ({ adress }: Props) => (
  <>
    <div>{adress.name}</div>
    <div>{adress.street}</div>
    <div>
      <span>{adress.postal_zip_code} </span>
      <span>{adress.town_city}</span>
    </div>
    <div>
      <span>{adress.county_state} </span>
      <span>{adress.country}</span>
    </div>
  </>
)
