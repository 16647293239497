import { CheckoutLiveType, CheckoutType } from '@types'
import React, { useEffect, useReducer, createContext, useContext } from 'react'

import { CartStateContext } from '../cart'

import { generateCheckout } from './actions/generateCheckout'
import { CheckoutAction, CheckoutState } from './types'

const reducer = (state: CheckoutState, action: CheckoutAction): CheckoutState => {
  switch (action.type) {
    case 'UPDATED_CHECKOUT':
      return {
        ...state,
        checkout: action.payload!.checkout!,
        live: action.payload!.checkout!.live,
        loaded: true,
      }
    case 'UPDATED_LIVE':
      return {
        ...state,
        live: action.payload!.live!,
        loaded: true,
      }
    case 'UPDATED_DISCOUNT':
      return {
        ...state,
        live: action.payload!.live!,
        isDiscountValid: action.payload!.isDiscountValid!,
        loaded: true,
      }
    case 'REFRESH':
      return {
        checkout: {} as CheckoutType,
        live: {} as CheckoutLiveType,
        isDiscountValid: null,
        loaded: false,
      }
    case 'LOADING':
      return {
        ...state,
        loaded: false,
      }
    case 'UPDATING':
      return {
        ...state,
      }
    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const CheckoutStateContext = createContext({} as CheckoutState)
export const CheckoutDispatchContext = createContext({} as React.Dispatch<CheckoutAction>)

interface Props {
  readonly children: React.ReactNode
}

export const CheckoutProvider = ({ children }: Props) => {
  const { cart, loaded } = useContext(CartStateContext)
  const [state, dispatch] = useReducer(reducer, {
    checkout: {} as CheckoutType,
    live: {} as CheckoutLiveType,
    isDiscountValid: null,
    loaded: false,
  })

  useEffect(() => {
    if (loaded) {
      generateCheckout(dispatch, cart)
    }
  }, [loaded])

  return (
    <CheckoutDispatchContext.Provider value={dispatch}>
      <CheckoutStateContext.Provider value={state}>{children}</CheckoutStateContext.Provider>
    </CheckoutDispatchContext.Provider>
  )
}
