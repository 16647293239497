import styled from 'styled-components'

export const CheckoutSummaryHeader = styled.h2`
  text-transform: uppercase;
`

export const CheckoutSummarySubtotal = styled.h1``

interface CheckoutSummaryRowProps {
  readonly isBorder?: boolean
}

export const CheckoutSummaryRow = styled.div<CheckoutSummaryRowProps>`
  border-top: ${({ isBorder }) => (isBorder ? '1px solid black' : 'none')};
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-top: 1rem;
`
export const CheckoutSummaryHeading = styled.h3`
  text-transform: uppercase;
  margin: 0;
`
export const CheckoutSummarySubHeading = styled.p`
  margin: 0;
`
