import { CustomerDataType } from '@types'

export const convertCustomerData = (customerData: CustomerDataType) => ({
  customer: {
    firstname: customerData.firstName,
    lastname: customerData.lastName,
    email: customerData.email,
  },
  shipping: {
    name: customerData.name,
    street: customerData.street,
    postal_zip_code: customerData.postal_zip_code,
    town_city: customerData.town_city,
    country: customerData.country,
    county_state: customerData.region,
  },
  fulfillment: {
    shipping_method: customerData.shipping_method,
  },
  billing: {
    name: customerData.billingName || customerData.name,
    street: customerData.billingStreet || customerData.street,
    postal_zip_code: customerData.billingPostal_zip_code || customerData.postal_zip_code,
    town_city: customerData.billingTown_city || customerData.town_city,
    country: customerData.billingCountry || customerData.country,
    county_state: customerData.billingRegion || customerData.region,
  },
})
