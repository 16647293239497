import styled from 'styled-components'

export const DefaultHeading = styled.h1`
  text-transform: uppercase;
  color: #000000;
  white-space: pre-line;
  margin-top: 0;
`

interface StyledHeadingProps {
  readonly isMargin: boolean
}

export const StyledHeading = styled(DefaultHeading)<StyledHeadingProps>`
  color: #880606;
  font-size: 2.4rem;
  margin-bottom: 0;
  margin-top: ${({ isMargin }) => (isMargin ? '2rem' : '0rem')};
`
