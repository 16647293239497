/* eslint-disable no-useless-escape */
export const filterDescription = (description: string) => {
  let newDescription: string = description
  const linkRegex = /\[([^\[]+)\]\(([^\)]*)\)/g

  const singleLinkRegex = /\[([^\[]+)\]\((.*)\)/

  const linkMatches = description.match(linkRegex)
  if (!linkMatches) {
    return newDescription
  }
  for (let i = 0; i < linkMatches.length; i += 1) {
    const [full, text, url] = linkMatches[i].match(singleLinkRegex)!
    newDescription = newDescription.replace(full, `<a href="${url}">${text}</a>`)
  }
  return newDescription
}
