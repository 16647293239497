import { convertCustomerData } from '@helper'
import { commerce } from '../../commerce'

export const fetchPayPalAuth = (checkoutToken, customerData) => {
  return commerce.checkout
    .capture(checkoutToken.id, {
      _line_items: checkoutToken.products,
      ...convertCustomerData(customerData),
      payment: {
        gateway: 'paypal',
        paypal: {
          action: 'authorize',
        },
      },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log('There was an error', error)
      return []
    })
}
