import styled from 'styled-components'

export const ProductDetailVariantsHeader = styled.h3`
  text-transform: uppercase;
`

export const ProductDetailVariantsItem = styled.div``

export const ProductDetailVariantsButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

interface ButtonProps {
  readonly isSelected: boolean
  readonly isAvailable: boolean
}

export const ProductDetailVariantsButton = styled.div<ButtonProps>`
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: ${({ isAvailable }) => (isAvailable ? 'pointer' : 'auto')};
  transition: transform 0.25s ease-out, background 0.25s ease-out;
  border: ${({ isSelected }) => (isSelected ? '2px solid #880606' : '2px solid black')};
  background-color: white;
  opacity: ${({ isAvailable }) => (isAvailable ? '1' : '0.3')};
  color: black;
  padding: 0.4rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 5px;
  box-shadow: 0 1.2px 2.2px rgba(0, 0, 0, 0.02), 0 2.8px 5.3px rgba(0, 0, 0, 0.028), 0 5.3px 10px rgba(0, 0, 0, 0.035),
    0 9.4px 17.9px rgba(0, 0, 0, 0.042), 0 17.5px 33.4px rgba(0, 0, 0, 0.05), 0 42px 80px rgba(0, 0, 0, 0.07);

  &:focus {
    outline: 0;
  }
`
