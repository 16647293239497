import { useState, useEffect } from 'react'
import { fetchProducts } from '@api'
import { ProductType } from '@types'

export const useProducts = (): [Array<ProductType>, boolean] => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [products, setProducts] = useState([])

  useEffect(() => {
    const loadProducts = async () => {
      setProducts(await fetchProducts())
      setIsLoaded(true)
    }

    loadProducts()
  }, [])

  return [products, isLoaded]
}
