import React from 'react'

interface Props {
  readonly width: string
  readonly height: string
}

export const ShoppingCartIcon = ({ width, height }: Props) => (
  <svg viewBox="0 0 297.22 253.22" width={width} height={height} className="shopping-cart">
    <style>
      {`.cls-1 {
        fill: none;
        stroke: white;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 14px;
      }`}
    </style>
    <polyline className="cls-1" points="7 7 61.29 7 107 198.43 252 198.43" />
    <circle className="cls-1" cx="114.34" cy="222.63" r="23.59" />
    <circle className="cls-1" cx="251.14" cy="221.95" r="23.59" />
    <polyline className="cls-1" points="73.15 48.19 288.13 49.66 258.21 162.28 104.53 162.28" />
    <line className="cls-1" x1="141.19" y1="52" x2="152.04" y2="159.93" />
    <line className="cls-1" x1="217.16" y1="49.17" x2="205.42" y2="162.28" />
    <line className="cls-1" x1="87.81" y1="105.97" x2="273.17" y2="105.97" />
  </svg>
)
