import React from 'react'
import ImageGallery from 'react-image-gallery'
import { ProductType, VariantType } from '@types'

import { ProductDetailImage } from './ProductDetailGalleryStyles'

interface Props {
  readonly product: ProductType
  readonly variant: VariantType | null
}

export const ProductDetailGallery = ({ product, variant }: Props) => {
  const getImagesFromProduct = () =>
    variant && variant.assets && variant.assets.length
      ? variant.assets.map((asset) => ({
          original: asset.url,
          thumbnail: asset.url,
        }))
      : product.assets.map((asset) => ({
          original: asset.url,
          thumbnail: asset.url,
        }))
  return (
    <ProductDetailImage>
      <ImageGallery items={getImagesFromProduct()} showPlayButton={false} showFullscreenButton={false} />
    </ProductDetailImage>
  )
}
