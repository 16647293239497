import styled from 'styled-components'

export const CheckoutInputRow = styled.div`
  display: flex;
`

export const CheckoutSearch = styled.div`
  .mapboxgl-ctrl-geocoder {
    /* padding: 12px 20px; */
    margin: 8px 0;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    padding: 0.2rem 0;
    border: 1px solid #ccc;
    max-width: none;
    box-shadow: none;
  }
  .mapboxgl-ctrl-geocoder--icon {
    top: 10px;
  }
  width: 100%;
`
interface CheckoutInputProps {
  readonly isError: boolean
}

export const CheckoutInput = styled.input<CheckoutInputProps>`
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  border: ${({ isError }) => (isError ? '1px solid red' : '1px solid #ccc')};
`

export const CheckoutSelect = styled.select`
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  appearance: none;
`

export const CheckoutCheckbox = styled.input`
  padding: 12px 20px;
  margin: 8px 8px 16px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
`

interface CheckoutErrorProps {
  readonly isVisible: boolean
}

export const CheckoutError = styled.div<CheckoutErrorProps>`
  color: red;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`
