import { commerce } from '../../commerce'

export const fetchProduct = (productId) => {
  return commerce.products
    .retrieve(productId)
    .then((product) => {
      return product
    })
    .catch((error) => {
      console.log('There was an error retrieving the product', error)
      return {}
    })
}
