import React from 'react'
import { formatPrice } from '@helper'
import { ProductType } from '@types'
import {
  ProductItemBox,
  ProductItemDescription,
  ProductItemImageWrapper,
  ProductItemImage,
  ProductItemPrice,
  ProductItemPriceBox,
  ProductItemTextBox,
  ProductItemTitle,
} from './ProductListItemStyles'

interface Props {
  readonly product: ProductType
  readonly index: number
}

export const ProductListItem = ({ product, index }: Props) => (
  <ProductItemBox to={`/product/${product.permalink}`} key={product.id} delay={index}>
    <ProductItemImageWrapper>
      <ProductItemImage
        style={{
          backgroundImage: `url(${product.media.source}`,
        }}
      />
    </ProductItemImageWrapper>
    <ProductItemTextBox>
      <ProductItemTitle>{product.name}</ProductItemTitle>
      <ProductItemDescription>{product.seo.description}</ProductItemDescription>
      <ProductItemPriceBox>
        <ProductItemPrice>{formatPrice(product.price.raw)}</ProductItemPrice>
      </ProductItemPriceBox>
    </ProductItemTextBox>
  </ProductItemBox>
)
