import React from 'react'
import Flickity from 'react-flickity-component'
import { Container } from '@components'
import { ProductType } from '@types'

import {
  SliderHeading,
  SliderBox,
  SliderSlide,
  SliderImageWrapper,
  SliderImage,
  SliderText,
} from './MoreProductsStyles'

interface Props {
  readonly products: Array<ProductType>
}

export const MoreProducts = ({ products }: Props) => {
  return (
    <SliderBox>
      <Container>
        <SliderHeading>Weitere Burner</SliderHeading>
        <Flickity
          className="carousel"
          disableImagesLoaded={false}
          reloadOnUpdate
          options={{ wrapAround: true, autoPlay: 5000, pageDots: false, initialIndex: 1 }}
          static
        >
          {products.map((product) => {
            return (
              <SliderSlide to={`/product/${product.permalink}`} key={product.id}>
                <SliderImageWrapper>
                  <SliderImage style={{ backgroundImage: `url(${product.media.source})` }} />
                  <SliderText>{product.name}</SliderText>
                </SliderImageWrapper>
              </SliderSlide>
            )
          })}
        </Flickity>
      </Container>
    </SliderBox>
  )
}
