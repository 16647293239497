import styled from 'styled-components'

export const HeroWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  position: relative;
`

export const HeroVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const HeroLogo = styled.div`
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -1px;
  bottom: 0;
  right: 0;
  width: 100%;
  background-position: center;
  background-size: 90%;
  background-repeat: no-repeat;
`

export const ArrowDown = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  padding: 3px;
  transform: rotate(45deg);
  animation: pulse 3s ease-in-out infinite;
  @keyframes pulse {
    0% {
      transform: rotate(45deg) scale(1, 1);
    }
    50% {
      transform: rotate(45deg) scale(1.3, 1.3);
    }
    100% {
      transform: rotate(45deg) scale(1, 1);
    }
  }
`
