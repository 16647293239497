import React from 'react'

import { DefaultHeading, StyledHeading } from './HeadingStyles'

interface Props {
  readonly title: string
  readonly subtitle: string
  readonly id?: string
  readonly isMargin?: boolean
}

export const Heading = ({ title, subtitle, id, isMargin }: Props) => (
  <>
    {title && (
      <StyledHeading id={id} isMargin={isMargin}>
        {title}
      </StyledHeading>
    )}
    {subtitle && <DefaultHeading>{subtitle}</DefaultHeading>}
  </>
)

Heading.defaultProps = {
  id: '',
  isMargin: true,
}
