import React, { useEffect, useState } from 'react'
import { ShoppingCartIcon, BoxIcon } from '@icons'

import { Button } from './ProductDetailButtonStyles'

interface Props {
  readonly isActive: boolean
  readonly isUpdated: boolean
  readonly onClickHandler: () => void
}

export const ProductDetailButton = ({ isActive, isUpdated, onClickHandler }: Props) => {
  const [infinteAnimationBuffer, setInfinteAnimationBuffer] = useState(false)
  const [endAnimationBuffer, setEndAnimationBuffer] = useState(false)

  useEffect(() => {
    if (isUpdated) {
      setInfinteAnimationBuffer(true)
    }
  }, [isUpdated])

  const checkUpdate = () => {
    if (!isUpdated) {
      setInfinteAnimationBuffer(false)
      setEndAnimationBuffer(true)
    }
  }

  const onButtonClick = () => {
    if (isActive && !(infinteAnimationBuffer || endAnimationBuffer)) {
      onClickHandler()
    }
  }

  return (
    <Button
      isActive={isActive}
      onClick={onButtonClick}
      playInfiniteAnimation={infinteAnimationBuffer}
      playEndAnimation={endAnimationBuffer}
      onAnimationIteration={() => checkUpdate()}
      onAnimationEnd={() => setEndAnimationBuffer(false)}
    >
      <span className="add-to-cart">In den Warenkorb</span>
      <span className="added">Hinzugefügt</span>
      <ShoppingCartIcon width="20px" height="20px" />
      <BoxIcon />
    </Button>
  )
}
