import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Alert } from '@components'

import { Cross } from '@icons'
import {
  PopUpOffScreen,
  PopUpBox,
  PopUpRow,
  PopUpInput,
  PopUpButton,
  PopUpHeading,
  PopUpSubHeading,
  PopUpSmallText,
  PopUpHeadingRow,
  PopUpAlertBox,
} from './ContactFormPopUpStyles'

interface ModalProps {
  readonly isVisible: boolean
  readonly onClickHandler: () => void
  readonly productName: string
}

export const ContactFormPopUp = ({ isVisible, onClickHandler, productName }: ModalProps) => {
  const [messageState, setMessageState] = useState(false)
  const [messageContent, setMessageContent] = useState('')
  const [messageSuccess, setMessageSuccess] = useState(true)

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)

    fetch('https://mailer.hellochili.de/popup.php', {
      method: 'POST',
      // headers: { 'content-type': 'application/json' },
      mode: 'cors',
      body: data,
    })
      .then((res) => {
        if (res.ok) {
          return res.text()
        }
        throw new Error('Ein Fehler ist aufgetreten')
      })
      .then((text: string) => {
        setMessageSuccess(true)
        setMessageState(true)
        setMessageContent(text)
      })
      .catch(() => {
        setMessageSuccess(false)
        setMessageState(true)
        setMessageContent('Ein Fehler ist aufgetreten')
      })
    // fakeFulfillment()
    // fakeRejection()
    setTimeout(() => setMessageState(false), 5000)
  }

  const fakeFulfillment = () => {
    setMessageSuccess(true)
    setMessageState(true)
    setMessageContent('Die Nachricht ist angekommen. Vielen Dank!')
  }

  const fakeRejection = () => {
    setMessageSuccess(false)
    setMessageState(true)
    setMessageContent(
      'Deine Nachricht ist leider nicht angekommen. Bitte versuche es später erneut oder nutze unsere anderen Kontaktmöglichkeiten.',
    )
  }

  return (
    <>
      <PopUpOffScreen isVisible={isVisible} onClick={onClickHandler} />
      <PopUpBox isVisible={isVisible} onSubmit={handleSubmit}>
        <input name="product" type="hidden" value={productName} />
        <PopUpHeadingRow>
          <PopUpHeading>Vielen Dank für dein Interesse</PopUpHeading>
          <Cross onClick={onClickHandler} />
        </PopUpHeadingRow>
        <PopUpSubHeading>Wir melden uns bei dir sobald das Produkt wieder verfügbar ist.</PopUpSubHeading>
        <PopUpRow>
          <PopUpInput id="name" name="name" type="text" placeholder="Vor- und Nachname" required />
        </PopUpRow>
        <PopUpRow>
          <PopUpInput id="email" name="email" type="email" placeholder="maxmusterman@example.com" required />
        </PopUpRow>
        <PopUpButton>Abschicken</PopUpButton>
        {messageState && (
          <PopUpAlertBox>
            <Alert isVisible={messageState} isSuccess={messageSuccess} message={messageContent} />
          </PopUpAlertBox>
        )}
        <PopUpSmallText>
          <span>Mit dem Klick auf &quot;Abschicken&quot; stimmst du unseren </span>
          <Link to="/datenschutz">Datenschutzerklärungen</Link>
          <span> zu.</span>
        </PopUpSmallText>
      </PopUpBox>
    </>
  )
}
