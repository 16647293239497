import { LoadingCircle } from '@icons'
import React from 'react'
import { LoadingBox, LoadingText } from './LoadingStyles'

export const Loading = () => (
  <LoadingBox>
    <LoadingCircle color="#880606" width="100px" height="100px" />
    <LoadingText>Lädt...</LoadingText>
  </LoadingBox>
)
