import { CheckoutLiveType } from '@types'
import { commerce } from '../../../commerce'

import { CheckoutAction } from '../types'

export const checkDiscount = (dispatch: React.Dispatch<CheckoutAction>, checkoutId: string, discountCode: string) => {
  dispatch({ type: 'UPDATING' })
  commerce.checkout
    .checkDiscount(checkoutId, {
      code: discountCode,
    })
    .then((res: { live: CheckoutLiveType; valid: boolean }) => {
      dispatch({
        type: 'UPDATED_DISCOUNT',
        payload: {
          isDiscountValid: res.valid,
          live: res.live,
        },
      })
    })
}
