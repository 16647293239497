import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

export const AboutUsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

export const AboutUsInnerBox = styled.div`
  margin-top: 1rem;
  @media (min-width: 768px) {
    margin: 0;
    flex-basis: 50%;
  }
`

export const AboutMeImage = styled(GatsbyImage)`
  width: 100%;
  @media (min-width: 768px) {
    flex-basis: 50%;
    margin-right: 1rem;
    margin-bottom: 0;
  }
`
