/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { CountriesType, CustomerDataType } from '@types'

import { CheckoutSelect } from '../CheckoutInputStyles'

interface Props {
  readonly register: UseFormRegister<CustomerDataType>
  readonly country: string
  readonly setCountry: (newCountry: string) => void
  readonly countries: CountriesType
  readonly inputName: 'country' | 'billingCountry'
}

export const CheckoutCountryList = ({ register, country, setCountry, inputName, countries }: Props) => {
  const onChangeHandler = (e: { target: { value: string } }) => {
    setCountry(e.target.value)
  }

  return (
    <>
      <input value={country} type="hidden" {...register(inputName, { required: true })} />
      <CheckoutSelect value={country} onChange={onChangeHandler}>
        {countries.countries &&
          Object.entries(countries.countries).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
      </CheckoutSelect>
    </>
  )
}
