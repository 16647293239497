import React, { useContext } from 'react'
import { QuantitySelector } from '@components'
import { useProduct } from '@hooks'
import { CheckoutDispatchContext, CheckoutStateContext, checkQuantity } from '@contexts'
import { ItemType } from '@types'

import {
  CheckoutSummaryItem,
  CheckoutSummaryItemImage,
  CheckoutSummaryItemInfo,
  CheckoutSummaryItemName,
  CheckoutSummaryItemOptions,
  CheckoutSummarySinglePrice,
} from './CheckoutProductItemStyles'

interface Props {
  readonly item: ItemType
}

export const CheckoutProductItem = ({ item }: Props) => {
  const [product, loaded] = useProduct(item.product_id)
  const { checkout } = useContext(CheckoutStateContext)
  const dispatch = useContext(CheckoutDispatchContext)

  const getVariantId = item.variant && item.variant.id ? item.variant.id : ''

  const getMaxAmount = () => {
    if (item.variant) {
      return item.variant.inventory
    }
    if (!item.variant && loaded) {
      return product.inventory.available
    }
    return item.quantity
  }

  return (
    <CheckoutSummaryItem>
      <CheckoutSummaryItem>
        <CheckoutSummaryItemImage style={{ backgroundImage: `url(${item.media.source})` }} />
        <CheckoutSummaryItemInfo>
          <CheckoutSummaryItemName>{item.name}</CheckoutSummaryItemName>
          {item.selected_options && (
            <CheckoutSummaryItemOptions>
              {item.selected_options.map((option, index) => (
                <span key={option.option_name}>
                  {option.option_name}
                  {index < item.selected_options.length - 1 ? ', ' : ''}
                </span>
              ))}
            </CheckoutSummaryItemOptions>
          )}
          <QuantitySelector
            amount={item.quantity}
            setAmount={(quantity) => checkQuantity(dispatch, checkout.id, item.id, quantity, getVariantId)}
            maxAmount={getMaxAmount()}
            isInline
          />
        </CheckoutSummaryItemInfo>
      </CheckoutSummaryItem>
      <CheckoutSummarySinglePrice>{`${item.quantity} x ${item.price.formatted} €`}</CheckoutSummarySinglePrice>
    </CheckoutSummaryItem>
  )
}
