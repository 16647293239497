import styled, { keyframes, css } from 'styled-components'

const cart = keyframes`
  0% {
    left: -10%;
  }
  40%, 60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
`

const box = keyframes`
  0%, 40% {
    top: -20%;
  }
  60% {
    top: 40%;
    left: 51%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
`
const visibleText = keyframes`
  0%, 90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

interface ButtonProps {
  isActive: boolean
  playInfiniteAnimation: boolean
  playEndAnimation: boolean
}

export const Button = styled.button<ButtonProps>`
  position: relative;
  transition: 0.3s ease-in-out;
  height: 60px;
  overflow: hidden;
  padding: 1.5rem 0.5rem;
  width: 100%;
  background-color: black;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: ${({ isActive, playInfiniteAnimation, playEndAnimation }) =>
    isActive && !(playEndAnimation || playInfiniteAnimation) ? 'pointer' : 'auto'};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.3')};
  animation: ${({ isActive }) =>
    isActive &&
    css`
      ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
    `};

  .shopping-cart {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: -10%;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
    animation: ${({ playInfiniteAnimation }) =>
      playInfiniteAnimation &&
      css`
        ${cart} 1.5s ease-in-out infinite forwards
      `};
  }

  .box {
    position: absolute;
    z-index: 3;
    top: -20%;
    left: 52%;
    width: 1.2rem;
    height: 1.2rem;
    transform: translate(-50%, -50%);
    animation: ${({ playInfiniteAnimation }) =>
      playInfiniteAnimation &&
      css`
        ${box} 1.5s ease-in-out infinite forwards
      `};
  }

  span {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    font-size: 1.2em;
    color: #fff;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;
    &.add-to-cart {
      opacity: ${({ playInfiniteAnimation, playEndAnimation }) =>
        playInfiniteAnimation || playEndAnimation ? '0' : '1'};
    }
    &.added {
      opacity: 0;
      animation: ${({ playEndAnimation }) =>
        playEndAnimation &&
        css`
          ${visibleText} 3s ease-in-out forwards
        `};
    }
  }
`
