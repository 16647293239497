import React from 'react'
import { times } from 'lodash'
import { Alert } from '@components'

import {
  QuantitySelectorWrapper,
  QuantitySelectorHeader,
  QuantitySelectorSelect,
  QuantitySelectorBox,
} from './QuantitySelectorStyles'

interface Props {
  readonly amount: number
  readonly setAmount: (amount: number) => void
  readonly maxAmount: number | undefined
  readonly isAlert?: { alert: boolean; message: string }
  readonly onAlertClickHandler?: () => void
  readonly isInline?: boolean
}

const renderOptions = (maxAmount: number | undefined) => {
  if (!maxAmount) {
    return <option value={0}>{0}</option>
  }
  if (maxAmount && maxAmount >= 1 && maxAmount <= 10) {
    return times(maxAmount, (number) => (
      <option key={number} value={number + 1}>
        {number + 1}
      </option>
    ))
  }
  if (maxAmount && maxAmount > 10) {
    return times(10, (number) => (
      <option key={number} value={number + 1}>
        {number + 1}
      </option>
    ))
  }
  return null
}

export const QuantitySelector = ({ amount, setAmount, maxAmount, isAlert, isInline, onAlertClickHandler }: Props) => (
  <QuantitySelectorWrapper isInline={!!isInline}>
    <QuantitySelectorHeader>Menge</QuantitySelectorHeader>
    <QuantitySelectorBox>
      <QuantitySelectorSelect
        isEnabled={!!maxAmount}
        value={amount}
        onChange={(e) => setAmount(parseInt(e.target.value, 10))}
      >
        {renderOptions(maxAmount)}
      </QuantitySelectorSelect>
      {isAlert && (
        <Alert
          isVisible={isAlert.alert}
          message={isAlert.message}
          isSuccess={false}
          onClickHandler={onAlertClickHandler}
        />
      )}
    </QuantitySelectorBox>
  </QuantitySelectorWrapper>
)

QuantitySelector.defaultProps = {
  isAlert: undefined,
  isInline: false,
  onAlertClickHandler: () => null,
}
