import { isMatch } from 'lodash'
import { VariantType } from '@types'

const reduceVariantsInventory = (accumulator: number, currentValue: VariantType): number =>
  accumulator + currentValue.inventory

export const checkIfAvailable = (
  variantGroupId: string,
  variantOptionId: string,
  variants: Array<VariantType>,
  selectedVariantOptions: { [key: string]: string },
): boolean => {
  const availableVariants = variants.filter((variant) => variant.options[variantGroupId] === variantOptionId)
  const totalVariantInventory = availableVariants.length ? availableVariants.reduce(reduceVariantsInventory, 0) : 0

  if (Object.keys(selectedVariantOptions).length) {
    const otherAvailableVariants = variants.filter((variant) => isMatch(selectedVariantOptions, variant.options))
    const otherTotalVariantInventory = otherAvailableVariants.length
      ? availableVariants.reduce(reduceVariantsInventory, 0)
      : 0
    return totalVariantInventory > 0 || otherTotalVariantInventory > 0
  }

  return totalVariantInventory > 0
}
