import React from 'react'
import { NavBarOffScreenBox } from './NavBarOffScreenStyles'

interface Props {
  readonly isVisible: boolean
  readonly onClickHandler: () => void
}

export const NavBarOffScreen = ({ onClickHandler, isVisible }: Props) => (
  <NavBarOffScreenBox isVisible={isVisible} onClick={onClickHandler} />
)
