import React from 'react'

import { StyledLink } from './NavBarLinkStyles'

interface Props {
  readonly title: string
  readonly path: string
}

export const NavBarLink = ({ title, path }: Props) => (
  <StyledLink to={path} stripHash>
    {title}
  </StyledLink>
)
