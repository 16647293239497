import { CheckoutLiveType } from '@types'
import { commerce } from '../../../commerce'

import { CheckoutAction } from '../types'

export const checkShipping = (
  dispatch: React.Dispatch<CheckoutAction>,
  checkoutId: string,
  shippingId: string,
  country: string,
  region: string,
) => {
  dispatch({ type: 'UPDATING' })
  commerce.checkout
    .checkShippingOption(checkoutId, {
      shipping_option_id: shippingId,
      country,
      region,
    })
    .then((res: { live: CheckoutLiveType }) => {
      dispatch({
        type: 'UPDATED_LIVE',
        payload: {
          live: res.live,
        },
      })
    })
}
