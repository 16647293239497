import styled from 'styled-components'

export const NavBarMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NavBarMenuBox = styled.nav`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`
