import styled from 'styled-components'

interface NavBarMobileMenuWrapper {
  isOpen: boolean
}

export const NavBarMobileMenuWrapper = styled.div<NavBarMobileMenuWrapper>`
  position: absolute;
  width: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  right: 0;
  overflow: hidden;
  z-index: 104;
`

interface NavBarMobileMenuProps {
  readonly isOpen: boolean
}

export const NavBarMobileMenu = styled.div<NavBarMobileMenuProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: rgba(0, 0, 0, 0.85);
  padding: ${({ isOpen }) => (isOpen ? '1rem' : '0')};
  height: ${({ isOpen }) => (isOpen ? '100vh' : '0')};
  width: ${({ isOpen }) => (isOpen ? '200px' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(200px)')};
`

export const InvisibleButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 1rem 0rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`
