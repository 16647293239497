import React, { useContext } from 'react'
import { Paragraph } from '@components'
import { CheckoutStateContext } from '@model'
import {
  CheckoutShortSummaryBox,
  CheckoutShortSummaryImage,
  CheckoutShortSummaryInformation,
  CheckoutShortSummarySubHeading,
  CheckoutShortSummaryTotal,
} from './CheckoutShortSummaryStyles'
import { countAmount } from './countAmount'

export const CheckoutShortSummary = () => {
  const { live, loaded } = useContext(CheckoutStateContext)
  return loaded && live ? (
    <CheckoutShortSummaryBox>
      <CheckoutShortSummaryImage style={{ backgroundImage: `url(${live.line_items[0].media.source})` }} />
      <CheckoutShortSummaryInformation>
        <CheckoutShortSummarySubHeading>{`${countAmount(live.line_items)} Artikel`}</CheckoutShortSummarySubHeading>
        <CheckoutShortSummaryTotal>{`${live.total.formatted} €`}</CheckoutShortSummaryTotal>
        <Paragraph>inkl. Versand</Paragraph>
      </CheckoutShortSummaryInformation>
    </CheckoutShortSummaryBox>
  ) : null
}
