import { ProductType, VariantType } from '@types'

const reduceVariantsInventory = (accumulator: number, currentValue: VariantType): number =>
  accumulator + currentValue.inventory

export const checkAvailabilty = (product: ProductType, variants: Array<VariantType>) => {
  if (variants && variants.length > 0) {
    return variants.reduce(reduceVariantsInventory, 0)
  }

  if (product.inventory) {
    return product.inventory.available
  }

  return 0
}
