import styled from 'styled-components'

export const CheckoutPaymentSwitchContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 1rem;
`

interface CheckoutPaymentSwitchButtonProps {
  isSelected: boolean
  isDisabled?: boolean
}

export const CheckoutPaymentSwitchButton = styled.button<CheckoutPaymentSwitchButtonProps>`
  flex: 1;
  margin: 0;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  outline: inherit;
  border-radius: 10px;
  border: ${({ isSelected }) => (isSelected ? '2px solid black' : '2px solid transparent')};
  path {
    opacity: ${({ isDisabled }) => (isDisabled ? '0.2' : '1')};
    color: black;
  }
  position: relative;
`

export const CheckoutPaymentSwitchText = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 30%;
  left: 0;
  right: 0;
  color: gray;
`
