import styled from 'styled-components'

export const CheckoutSummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CheckoutSummaryItemImage = styled.div`
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
`
export const CheckoutSummaryItemInfo = styled.div`
  margin-left: 1rem;
`
export const CheckoutSummaryItemName = styled.h3`
  text-transform: uppercase;
  margin-bottom: 0;
`
export const CheckoutSummaryItemOptions = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`

export const CheckoutSummaryItemAmount = styled.div``

export const CheckoutSummarySinglePrice = styled.h3``
