import styled from 'styled-components'

interface CookieLawBoxProps {
  visible: boolean
}

export const CookieLawBox = styled.div<CookieLawBoxProps>`
  display: flex;
  bottom: 0;
  position: fixed;
  background-color: black;
  color: white;
  min-height: 75px;
  z-index: 999;
  width: 100%;
  padding: 0.5rem 0rem 1.5rem;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  height: ${(props) => (props.visible ? 'auto' : '0')};
  transition: opacity 0.3s ease-in-out;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  box-shadow: 0 0.5px 2.2px rgba(0, 0, 0, 0.037), 0 1.3px 5.3px rgba(0, 0, 0, 0.053), 0 2.4px 10px rgba(0, 0, 0, 0.065),
    0 4.2px 17.9px rgba(0, 0, 0, 0.077), 0 7.9px 33.4px rgba(0, 0, 0, 0.093), 0 19px 80px rgba(0, 0, 0, 0.13);
  @media (min-width: 768px) {
    padding: 0.5rem 0rem;
    flex-direction: row;
  }
`
export const CookieText = styled.div`
  padding: 1.5rem;
  @media (min-width: 768px) {
    width: 80%;
  }
  a {
    color: white;
  }
`
