import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { NavBar as DefaultNavBar } from '../components'

const menu = [
  {
    title: 'Über',
    path: '/#aboutus',
  },
  {
    title: 'Produkte',
    path: '/products',
  },
  {
    title: 'Wiki',
    path: '/wiki',
  },
  {
    title: 'Kontakt',
    path: '/#contact',
  },
]

export const NavBar = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: directus {
        ...LogoFragment
      }
    }
  `)

  return <DefaultNavBar logo={data.logo.logo.image} menu={menu} />
}
