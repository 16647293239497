import { isEqual } from 'lodash'
import React, { useState } from 'react'
import { VariantType, VariantGroupType } from '@types'

import { checkIfAvailable } from './checkIfAvailable'
import {
  ProductDetailVariantsHeader,
  ProductDetailVariantsButtonRow,
  ProductDetailVariantsButton,
  ProductDetailVariantsItem,
} from './ProductDetailVariantsStyles'

interface Props {
  readonly variants: Array<VariantType>
  readonly variantGroups: Array<VariantGroupType>
  readonly setSelectedVariant: (selectedVariant: VariantType | null) => void
}

export const ProductDetailVariants = ({ variants, variantGroups, setSelectedVariant }: Props) => {
  const [selectedVariantOptions, setSelectedVariantOptions] = useState({} as { [key: string]: string })

  const handleVariantSelect = (variantGroupId: string, variantOptionId: string) => {
    const newSelectedVariantOptions = { ...selectedVariantOptions, [variantGroupId]: variantOptionId }
    setSelectedVariantOptions(newSelectedVariantOptions)
    setSelectedVariant(variants.find((variant) => isEqual(variant.options, newSelectedVariantOptions)) || null)
  }

  const isVariantSelected = (variantGroupId: string, variantOptionId: string) =>
    Object.prototype.hasOwnProperty.call(selectedVariantOptions, variantGroupId) &&
    selectedVariantOptions[variantGroupId] === variantOptionId

  return (
    <>
      {variantGroups.map((variantGroup) => (
        <ProductDetailVariantsItem key={variantGroup.id}>
          <ProductDetailVariantsHeader>{variantGroup.name}</ProductDetailVariantsHeader>
          <ProductDetailVariantsButtonRow>
            {variantGroup.options.map((variantOption) => {
              const isAvailable = checkIfAvailable(variantGroup.id, variantOption.id, variants, selectedVariantOptions)
              return (
                <ProductDetailVariantsButton
                  key={variantOption.id}
                  isSelected={isVariantSelected(variantGroup.id, variantOption.id)}
                  isAvailable={isAvailable}
                  onClick={() => isAvailable && handleVariantSelect(variantGroup.id, variantOption.id)}
                >
                  {variantOption.name}
                </ProductDetailVariantsButton>
              )
            })}
          </ProductDetailVariantsButtonRow>
        </ProductDetailVariantsItem>
      ))}
    </>
  )
}
