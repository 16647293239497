import { Stripe } from '@stripe/stripe-js'
import { CheckoutType, CustomerDataType, OrderType, PaymentType, ResponseType } from '@types'
import { convertCustomerData } from '@helper'

import { commerce } from '../../../commerce'

import { refreshCart, CartAction } from '../../cart'

import { CheckoutAction } from '../types'

export const secureConfirmOrder = async (
  stripe: Stripe,
  checkoutDispatch: React.Dispatch<CheckoutAction>,
  cartDispatch: React.Dispatch<CartAction>,
  checkoutToken: CheckoutType,
  customerData: CustomerDataType,
  payment: PaymentType,
) => {
  checkoutDispatch({ type: 'UPDATING' })
  return commerce.checkout
    .capture(checkoutToken.id, {
      _line_items: checkoutToken.products,
      ...convertCustomerData(customerData),
      payment,
    })
    .then((order: OrderType) => {
      checkoutDispatch({ type: 'REFRESH' })
      refreshCart(cartDispatch, order)
    })
    .catch(async (response: ResponseType) => {
      if (response.statusCode === 422 || response.data.error.type === 'unprocessable_entity') {
        return { isVisible: true, message: 'Ein Fehler ist aufgetreten. Eines der Produkte ist nicht mehr verfügbar' }
      }

      if (response.statusCode !== 402 || response.data.error.type !== 'requires_verification') {
        return { isVisible: true, message: 'Ein Fehler ist aufgetreten. Bitte versuche es erneut' }
      }

      const cardActionResult = await stripe.handleCardAction(response.data.error.param)
      if (cardActionResult.error) {
        // The customer failed to authenticate themselves with their bank and the transaction has been declined
        return {
          isVisible: true,
          message: 'Ein Fehler ist bei der Bestätigung der Zahlung aufgetreten. Bitte versuche es erneut',
        }
      }

      return commerce.checkout
        .capture(checkoutToken.id, {
          _line_items: checkoutToken.products,
          ...convertCustomerData(customerData),
          payment: {
            ...payment,
            stripe: {
              payment_intent_id: cardActionResult.paymentIntent.id,
            },
          },
        })
        .then((order: OrderType) => {
          checkoutDispatch({ type: 'REFRESH' })
          refreshCart(cartDispatch, order)
          return { isVisible: false, message: '' }
        })
        .catch(() => ({ isVisible: true, message: 'Deine Karte wurde abgelehnt' }))
    })
}
