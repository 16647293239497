import styled from 'styled-components'

export const CheckoutPageBox = styled.div`
  position: relative;
  overflow-x: hidden;
  min-height: 80vh;
  display: flex;
  flex-wrap: nowrap;
`

interface CheckoutPageProps {
  readonly index: number
  readonly pageNumber: number
}

export const CheckoutPage = styled.div<CheckoutPageProps>`
  width: 100%;
  position: ${({ pageNumber, index }) => pageNumber !== index && 'absolute'};
  visibility: ${({ pageNumber, index }) => pageNumber !== index && 'hidden'};
  opacity: ${({ pageNumber, index }) => pageNumber !== index && '0'};
  left: 0;
  left: ${({ pageNumber, index }) => 100 * (index - pageNumber)}%;
  transition: all 0.3s ease-in-out, visibility 1s ease-in-out;
`

export const CheckoutHeading = styled.h2`
  text-transform: uppercase;
`

export const CheckoutSubmitButton = styled.button`
  border-radius: 5px;
  cursor: pointer;
  padding: 1.5rem 0.5rem;
  width: 100%;
  background-color: black;
  color: white;
  font-size: 1.4rem;
  border: none;
  text-transform: uppercase;
`
