import React from 'react'

import { HamburgerBox, HamburgerBar } from './HamburgerStyles'

interface HamburgerProps {
  readonly isOpen: boolean
  readonly onClickHandler: () => void
}

export const Hamburger = ({ isOpen, onClickHandler }: HamburgerProps) => (
  <HamburgerBox onClick={onClickHandler}>
    <HamburgerBar number={1} isOpen={isOpen} />
    <HamburgerBar number={2} isOpen={isOpen} />
    <HamburgerBar number={3} isOpen={isOpen} />
  </HamburgerBox>
)
