import { useState, useEffect } from 'react'
import { ProductType, VariantType } from '@types'
import { fetchProduct, fetchVariantsByProduct } from '@api'

export const useProduct = (id: string): [ProductType, boolean, Array<VariantType>] => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [product, setProduct] = useState({} as ProductType)
  const [productVariants, setProductVariants] = useState([] as Array<VariantType>)

  useEffect(() => {
    const loadProduct = async () => {
      const loadedProduct = await fetchProduct(id)
      setProduct(loadedProduct)
      setProductVariants(await fetchVariantsByProduct(loadedProduct.id))
      setIsLoaded(true)
    }

    loadProduct()
  }, [id])

  return [product, isLoaded, productVariants]
}
