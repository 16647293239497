import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { CustomerDataType } from '@types'

import { stripePromise } from '../../../model/stripe'

import { CheckoutPage, CheckoutPageBox } from './CheckoutProcessStyles'
import { CheckoutCustomerPage } from './CheckoutCustomerPage'
import { CheckoutPaymentPage } from './CheckoutPaymentPage'

export const CheckoutProcess = () => {
  const [customerData, setCustomerData] = useState({} as CustomerDataType)
  const [pageNumber, setPageNumber] = useState(0)

  const onCustomerPageHandler = (newCustomerData: CustomerDataType) => {
    setCustomerData(newCustomerData)
    setPageNumber(1)
    window.scrollTo(0, 0)
  }
  return (
    <CheckoutPageBox>
      <CheckoutPage index={0} pageNumber={pageNumber}>
        <CheckoutCustomerPage onSubmitHandler={onCustomerPageHandler} />
      </CheckoutPage>
      <CheckoutPage index={1} pageNumber={pageNumber}>
        <Elements stripe={stripePromise}>
          <CheckoutPaymentPage customerData={customerData} onBackHandler={() => setPageNumber(0)} />
        </Elements>
      </CheckoutPage>
    </CheckoutPageBox>
  )
}
