import { CartType } from '@types'
import { commerce } from '../../../commerce'

import { CartAction } from '../types'

export const retriveCart = (dispatch: React.Dispatch<CartAction>) => {
  dispatch({ type: 'LOADING' })
  commerce.cart.retrieve().then((cart: CartType) => {
    dispatch({
      type: 'FINISHED',
      payload: {
        cart,
      },
    })
  })
}
