import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import { OrderType } from '@types'
import { CartStateContext } from '@contexts'
import { Heading, Loading } from '@components'

import { ConfirmationShipping } from './ConfirmationShipping'
import { ConfirmationAdressBox } from './ConfirmationAdressBox'
import { ConfirmationOrderDetails } from './ConfirmationOrderDetails'

import {
  ConfirmationBox,
  ConfirmationGif,
  ConfirmationHeading,
  ConfirmationBiggerRow,
  ConfirmationRow,
} from './ConfirmationStyles'

import ShippingAnimation from './ShippingAnimation.gif'

interface Props {
  state:
    | {
        order: OrderType | undefined
      }
    | undefined
}

export const Confirmation = ({ state }: Props) => {
  const { loaded } = useContext(CartStateContext)
  useEffect(() => {
    if (loaded && state === null) {
      navigate('/products')
    }
  }, [loaded])

  return state && state.order ? (
    <>
      <Heading title={`Danke dir, ${state.order.customer.firstname}!`} subtitle="" />
      <ConfirmationBox>
        <ConfirmationBiggerRow>
          <ConfirmationHeading>Bestellbestätigung</ConfirmationHeading>
          <ConfirmationOrderDetails customerReference={state.order.customer_reference} />
          <ConfirmationGif src={ShippingAnimation} alt="Shipping" />
        </ConfirmationBiggerRow>
        <ConfirmationRow>
          <ConfirmationHeading>Versandadresse</ConfirmationHeading>
          <ConfirmationAdressBox adress={state.order.shipping} />
          <ConfirmationShipping adress={state.order.shipping} elementId="geoShipping" />
        </ConfirmationRow>
      </ConfirmationBox>
    </>
  ) : (
    <Loading />
  )
}
