import React, { useEffect } from 'react'

import { useWindowDimensions, useWindowScroll } from '@hooks'
import { MediaType } from '@types'

import { HeroWrapper, HeroVideo, HeroLogo, ArrowDown } from './HeroStyles'

interface Props {
  readonly isDataLoaded: boolean
  readonly anchor: string
  readonly video: MediaType
  readonly logo_desktop: MediaType
  readonly logo_mobile: MediaType
}

export const Hero = ({ isDataLoaded, anchor, video, logo_desktop, logo_mobile }: Props) => {
  const [scroll] = useWindowScroll()
  const [{ width, height }] = useWindowDimensions()

  const aspectRatioImage = width / height > 3 / 4
  const image = aspectRatioImage ? logo_desktop.mediaUrl.publicURL : logo_mobile.mediaUrl.publicURL

  const aspectRatioVideo = width / height >= 4096 / 2160 // Insert Video Resolution
  const videoStyles = aspectRatioVideo ? { width } : { height }

  useEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        const element = document.getElementById(anchor)
        if (element && scroll !== 0) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 5000)
    }
    if (isDataLoaded) {
      scrollToBottom()
    }
  }, [isDataLoaded])

  return (
    <HeroWrapper>
      <HeroLogo style={{ backgroundImage: `url(${image})` }} />
      <HeroVideo src={video.mediaUrl.publicURL} autoPlay playsInline muted loop style={{ ...videoStyles }} />
    </HeroWrapper>
  )
}
