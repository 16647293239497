import styled from 'styled-components'
import 'flickity/css/flickity.css'
import { Link } from 'gatsby'

export const SliderBox = styled.div`
  margin: 3rem 0 0;
  padding-top: 1rem;
  padding-bottom: 3rem;
  background-color: rgb(237, 237, 237);
`

export const SliderHeading = styled.h2`
  text-transform: uppercase;
`

export const SliderSlide = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 2rem;
  text-decoration: none;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
`

export const SliderImageWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 56.25%; // 16:9
  position: relative;
`
export const SliderImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
`

export const SliderText = styled.div`
  z-index: 2;
  color: white;
  font-size: 1.4rem;
  text-decoration: none;
  color: #bfbbb0;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 2rem;
  text-align: center;
  width: 100%;
  bottom: 0;
  position: absolute;
`

export const ArrowLeft = styled.div`
  position: absolute;
`
export const ArrowRight = styled.div`
  position: absolute;
`
