import { CartType } from '@types'
import React, { useEffect, useReducer, createContext } from 'react'

import { retriveCart } from './actions/retriveCart'
import { CartAction, CartState } from './types'

const reducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case 'FINISHED':
      return {
        ...state,
        cart: action.payload!.cart!,
        loaded: true,
        adding: false,
      }
    case 'LOADING':
      return {
        ...state,
        loaded: false,
      }
    case 'ADD_TO_CART':
      return {
        ...state,
        adding: true,
      }
    case 'UPDATING':
      return {
        ...state,
      }
    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const CartStateContext = createContext({} as CartState)
export const CartDispatchContext = createContext({} as React.Dispatch<CartAction>)

interface Props {
  readonly children: React.ReactNode
}

export const CartProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, { cart: {} as CartType, loaded: false, adding: false })

  useEffect(() => retriveCart(dispatch), [])

  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>{children}</CartStateContext.Provider>
    </CartDispatchContext.Provider>
  )
}
