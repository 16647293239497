import { fetchShippingCountries, fetchShippingSubdivisionsOfCountry } from '@api'
import { CheckoutStateContext } from '@contexts'
import { CountriesType, CustomerDataType, SubdivisionType } from '@types'
import { useContext, useEffect, useState } from 'react'
import { UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import { useAutoComplete } from './useAutoComplete'

export const useCountryAndRegion = (
  streetInputName: 'street' | 'billingStreet',
  zipInputName: 'postal_zip_code' | 'billingPostal_zip_code',
  townInputName: 'town_city' | 'billingTown_city',
  countryInputName: 'country' | 'billingCountry',
  regionInputName: 'region' | 'billingRegion',
  geocoderId: 'geoshipping' | 'geobilling',
  clearErrors: UseFormClearErrors<CustomerDataType>,
  setError: UseFormSetError<CustomerDataType>,
  setValue: UseFormSetValue<CustomerDataType>,
): [CountriesType, SubdivisionType, string, (newCountry: string) => void, string, (newRegion: string) => void] => {
  const { checkout } = useContext(CheckoutStateContext)
  const [countries, setCountries] = useState({} as CountriesType)
  const [subdivisions, setSubdivisions] = useState({} as SubdivisionType)
  const [country, setCountry] = useState('DE')
  const [region, setRegion] = useState('NW')
  const [adressObject] = useAutoComplete(geocoderId)

  const checkCountry = (newCountry: string): boolean => countries.countries && !!countries.countries[newCountry]
  const checkRegion = (newRegion: string): boolean =>
    subdivisions.subdivisions && !!subdivisions.subdivisions[newRegion]

  useEffect(() => {
    const getCountries = async () => checkout && setCountries(await fetchShippingCountries(checkout.id))
    getCountries()
  }, [checkout])

  useEffect(() => {
    const getSubdivisions = async () =>
      checkout && setSubdivisions(await fetchShippingSubdivisionsOfCountry(checkout.id, country))
    getSubdivisions()
  }, [checkout, country])

  useEffect(() => {
    clearErrors(countryInputName)
    clearErrors(regionInputName)
    if (!checkCountry(adressObject.country)) {
      setError(countryInputName, { type: 'validate' })
    }
    if (!checkRegion(adressObject.region)) {
      setError(regionInputName, { type: 'validate' })
    }
    if (checkCountry(adressObject.country) && checkRegion(adressObject.region)) {
      setValue(streetInputName, adressObject.street)
      setValue(zipInputName, adressObject.postal_zip_code)
      setValue(townInputName, adressObject.town_city)
      setCountry(adressObject.country)
      setRegion(adressObject.region)
    }
  }, [adressObject])

  return [countries, subdivisions, country, setCountry, region, setRegion]
}
