import React from 'react'
import { Chili } from '@icons'
import { LoadingScreenWrapper } from './LoadingScreenStyles'

interface Props {
  readonly isVisible: boolean
}

export const LoadingScreen = ({ isVisible }: Props) => {
  return (
    <LoadingScreenWrapper isVisible={isVisible}>
      <Chili />
    </LoadingScreenWrapper>
  )
}
