import React, { useState, useContext, Fragment } from 'react'
import { ItemType } from '@types'
import { deleteFromCart, CartDispatchContext } from '@contexts'
import { Trash } from '@icons'

import {
  ShoppingCartItemRow,
  ShoppingCartItemInformation,
  ShoppingCartItemImage,
  ShoppingCartItemName,
  ShoppingCartItemQuantity,
  ShoppingCartDeleteButton,
  ShoppingCartHeaderRow,
  ShoppingCartItemOptionBox,
  ShoppingCartItemConfigBox,
  ShoppingCartItemImageWrapper,
  ShoppingCartItemImageBox,
} from './ShoppingCartItemStyles'

interface Props {
  readonly item: ItemType
}

export const ShoppingCartItem = ({ item }: Props) => {
  const [isTrashOpen, setIsTrashOpen] = useState(false)
  const dispatch = useContext(CartDispatchContext)

  const existOptions = item.selected_options && item.selected_options.length > 0

  const renderOptions = () => (
    <ShoppingCartItemOptionBox>
      <>Optionen: </>
      {item.selected_options.map((option, index) => (
        <Fragment key={option.option_name}>
          {`${option.option_name}${index < item.selected_options.length - 1 ? ', ' : ''}`}
          <br />
        </Fragment>
      ))}
    </ShoppingCartItemOptionBox>
  )

  const onDeleteClick = () => {
    setIsTrashOpen(true)
    deleteFromCart(dispatch, item.id)
  }

  return (
    <ShoppingCartItemRow key={item.id}>
      <ShoppingCartItemImageBox>
        <ShoppingCartItemImageWrapper>
          <ShoppingCartItemImage style={{ backgroundImage: `url(${item.media.source})` }} />
        </ShoppingCartItemImageWrapper>
      </ShoppingCartItemImageBox>
      <ShoppingCartItemInformation>
        <ShoppingCartHeaderRow>
          <ShoppingCartItemName>{item.name}</ShoppingCartItemName>
        </ShoppingCartHeaderRow>
        <ShoppingCartItemConfigBox>
          <ShoppingCartItemQuantity>{`Menge: ${item.quantity}`}</ShoppingCartItemQuantity>
          {existOptions ? renderOptions() : null}
        </ShoppingCartItemConfigBox>
      </ShoppingCartItemInformation>
      <ShoppingCartDeleteButton onClick={onDeleteClick}>
        <Trash isOpen={isTrashOpen} />
      </ShoppingCartDeleteButton>
    </ShoppingCartItemRow>
  )
}
