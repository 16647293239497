import React from 'react'

import { AlertContainer } from './AlertStyles'

interface Props {
  readonly isVisible: boolean
  readonly isSuccess: boolean
  readonly message: string
  readonly onClickHandler?: () => void
}

export const Alert = ({ isVisible, isSuccess, message, onClickHandler }: Props) => (
  <AlertContainer
    isVisible={isVisible}
    isSuccess={isSuccess || false}
    onClick={onClickHandler}
    isClickable={!!onClickHandler}
  >
    {message}
  </AlertContainer>
)

Alert.defaultProps = {
  onClickHandler: () => null,
}
