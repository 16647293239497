import React from 'react'
import { ShoppingCartIcon } from '@components'
import { NavBarLink } from '../NavBarLink'

import { NavBarMenuWrapper, NavBarMenuBox } from './NavBarMenuStyles'
import { Hamburger } from '../Hamburger'

interface Props {
  readonly isHamburgerOpen: boolean
  readonly onShoppingCartClickHandler: () => void
  readonly onHamburgerClickHandler: () => void
  readonly menu: Array<{ title: string; path: string }>
}

export const NavBarMenu = ({ menu, isHamburgerOpen, onShoppingCartClickHandler, onHamburgerClickHandler }: Props) => (
  <NavBarMenuWrapper>
    <NavBarMenuBox>
      {menu.map(({ title, path }, index) => (
        <NavBarLink key={String(index)} title={title} path={path} />
      ))}
    </NavBarMenuBox>
    <ShoppingCartIcon handleVisible={onShoppingCartClickHandler} />
    <Hamburger onClickHandler={onHamburgerClickHandler} isOpen={isHamburgerOpen} />
  </NavBarMenuWrapper>
)
